export interface MultiProgressBarProps {
  loading: boolean;
  assigned: number;
  assignedText: string;
  inReview: number;
  inReviewText: string;
  approved: number;
  approvedText: string;
  notAssigned?: number;
  notAssignedText?: string;
}
export const MultiProgressBar = ({
  approved,
  approvedText,
  assigned,
  assignedText,
  inReview,
  inReviewText,
  loading,
  notAssigned = 0,
  notAssignedText = "",
}: MultiProgressBarProps) => {
  const total = assigned + inReview + approved + notAssigned;

  const getPercentage = (count: number) =>
    total > 0 ? (count / total) * 100 : 0;

  const progressItems = [
    ...(notAssigned
      ? [
          {
            color: "#FF9900",
            count: notAssigned,
            percentage: getPercentage(notAssigned),
            text: notAssignedText,
          },
        ]
      : []),
    {
      color: "#016BE2",
      count: assigned,
      percentage: getPercentage(assigned),
      text: assignedText,
    },
    {
      color: "#F26602",
      count: inReview,
      percentage: getPercentage(inReview),
      text: inReviewText,
    },
    {
      color: "#03A742",
      count: approved,
      percentage: getPercentage(approved),
      text: approvedText,
    },
  ];

  if (loading) {
    return (
      <div style={{ width: "538px" }}>
        <div className="progress">
          <div className="progress-bar progress-bar-ruby" role="progressbar" />
        </div>
      </div>
    );
  }
  return (
    <div style={{ width: "538px" }}>
      <div className="progress">
        {progressItems.map(
          ({ color, percentage }, index) =>
            percentage > 0 && (
              <div
                key={index}
                className="progress-bar progress-bar-ruby"
                role="progressbar"
                style={{ background: color, width: `${percentage}%` }}
              />
            )
        )}
      </div>

      <div
        className="progress-bar-labels"
        style={{
          columnGap: "10px",
          display: "flex",
          flexWrap: "wrap",
          margin: "10px 0 0 auto",
          maxWidth: "493px",
          rowGap: "5px",
        }}
      >
        {progressItems.map(({ color, count, text }, index) => (
          <div
            key={index}
            className="prog-bar-box"
            style={{
              alignItems: "center",
              display: "flex",
              gap: "4px",
            }}
          >
            <span
              className="prog-bar-dot"
              style={{
                background: color,
                borderRadius: "50%",
                display: "inline-block",
                height: "10px",
                width: "10px",
              }}
            />
            <span className="prog-bar-label">{text}</span>
            <span className="prog-bar-percentage">({count})</span>
          </div>
        ))}
      </div>
    </div>
  );
};

import { usePermitEvent } from "@hooks/crud/usePermitEvent";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Grid, Skeleton, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as RouteHelper from "@utils/routes";
import { PermitEventType } from "permit-one-common/src/entities/permitEvent";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import {
  PermitEventLineItem,
  permitEventTypeToString,
} from "permit-one-common/src/interfaces/permitEvent";
import { Link } from "react-router-dom";

import { Header } from "../Header/Header";

interface PermitEventLogProps {
  loading: boolean;
  permit?: PermitLineItem;
}

export const permitEventToLink = (permitEvent: PermitEventLineItem): string => {
  console.log(permitEvent);

  switch (permitEvent.eventType) {
    default:
    case PermitEventType.PermitCreated:
    case PermitEventType.PermitAssignedManager:
    case PermitEventType.PermitAssignedApprover:
    case PermitEventType.PermitApproved:
    case PermitEventType.PermitInReview:
    case PermitEventType.PermitRejected:
    case PermitEventType.PermitUpdated:
    case PermitEventType.PermitUploadedDocument:
      return RouteHelper.conditions(
        permitEvent.projectId,
        permitEvent.permitId
      );
    case PermitEventType.ConditionAssigned:
    case PermitEventType.ConditionApproved:
    case PermitEventType.ConditionInReview:
    case PermitEventType.ConditionRejected:
    case PermitEventType.ConditionUpdated:
    case PermitEventType.ConditionDocumentUploaded:
      return RouteHelper.conditions(
        permitEvent.projectId,
        permitEvent.permitId
      );
  }
};

export const permitEventTypeToIcon = (
  permitEventType: PermitEventType
): React.ReactNode => {
  switch (permitEventType) {
    case PermitEventType.PermitCreated:
    case PermitEventType.ConditionCreated:
      return (
        <TimelineDot color="success">
          <AddCircleOutline sx={{ color: "white" }} />
        </TimelineDot>
      );
    case PermitEventType.PermitAssignedManager:
    case PermitEventType.PermitAssignedApprover:
    case PermitEventType.ConditionAssigned:
      return (
        <TimelineDot color="secondary">
          <AssignmentIndIcon sx={{ color: "white" }} />
        </TimelineDot>
      );
    case PermitEventType.PermitApproved:
    case PermitEventType.ConditionApproved:
      return (
        <TimelineDot color="success">
          <CheckCircleOutline sx={{ color: "white" }} />
        </TimelineDot>
      );
    case PermitEventType.PermitInReview:
    case PermitEventType.ConditionInReview:
      return (
        <TimelineDot color="warning">
          <VisibilityIcon sx={{ color: "white" }} />
        </TimelineDot>
      );
    case PermitEventType.PermitRejected:
    case PermitEventType.ConditionRejected:
      return (
        <TimelineDot color="error">
          <CancelIcon sx={{ color: "white" }} />
        </TimelineDot>
      );
    case PermitEventType.PermitUpdated:
    case PermitEventType.ConditionUpdated:
      return (
        <TimelineDot color="secondary">
          <EditIcon sx={{ color: "white" }} />
        </TimelineDot>
      );
    case PermitEventType.PermitUploadedDocument:
    case PermitEventType.ConditionDocumentUploaded:
      return (
        <TimelineDot color="primary">
          <CloudUploadIcon sx={{ color: "white" }} />
        </TimelineDot>
      );
  }
  return (
    <TimelineDot color="primary">
      <AddCircleOutline sx={{ color: "white" }} />
    </TimelineDot>
  );
};

export const PermitTimelineSkeleton: React.FC = () => {
  const skeletonItems = Array.from({ length: 5 });

  return (
    <Timeline>
      {skeletonItems.map((_, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Skeleton
              variant="text"
              width={100}
              animation="wave"
              sx={{ marginLeft: "auto" }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              animation="wave"
            />
            {index < skeletonItems.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Skeleton variant="text" width="40%" animation="wave" />
            <Skeleton variant="text" width="60%" animation="wave" />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const StyledLink = styled(Link)({
  "&:hover": {
    color: "gray",
    textDecoration: "underline",
  },
  color: "inherit",
  textDecoration: "none",
  transition: "color 0.2s ease-in-out",
});

export const PermitEventLog = ({
  loading,
  permit,
}: PermitEventLogProps): JSX.Element => {
  const { isPermitEventLoading, permitEvents } = usePermitEvent(permit?.id);
  const isLoading = loading || isPermitEventLoading;

  return (
    <>
      <Grid item xs={12} md={12}>
        <Header mainTitle="Event Log" loading={isLoading} isSubheading />
        <Grid item md={12}>
          {isLoading ? <PermitTimelineSkeleton /> : null}
          {!isLoading ? (
            <Timeline position="alternate">
              {permitEvents.map((event, index) => (
                <StyledLink to={permitEventToLink(event)} key={event.id}>
                  <TimelineItem key={event.id}>
                    <TimelineOppositeContent
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography variant="body2">
                        {event.created.toLocaleDateString()}{" "}
                        {event.created.toLocaleTimeString()}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      {permitEventTypeToIcon(event.eventType)}
                      {index < permitEvents.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ color: "black" }}
                      >
                        {permitEventTypeToString(event.eventType)}
                      </Typography>
                      <Typography variant="body2">
                        {event.info}
                        {event?.name ? ` by ${event.name}` : ""}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </StyledLink>
              ))}
              {permitEvents.length == 0 ? (
                <Typography
                  sx={{ color: "black", fontSize: "16px", fontWeight: "500" }}
                >
                  No permit events
                </Typography>
              ) : null}
            </Timeline>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

import { Chip, Grid, Stack, styled } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CellAction,
  CollaboratorsActionCell,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  ArchiveProjectIcon,
  EditProjectIcon,
  ReloadIcon,
  ViewProjectIcon,
} from "assets/constants";
import { permitTypeToString } from "permit-one-common/src/entities/permit";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import {
  isPermitManager,
  PermitLineItem,
  permitStatusToColor,
  permitStatusToString,
} from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import {
  isProjectOwner,
  ProjectLineItem,
} from "permit-one-common/src/interfaces/project";

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

interface PermitTable2Props {
  loading: boolean;
  project?: ProjectLineItem;
  permits: PermitLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  searchTerm: string;
  handleViewPermit: (permit: PermitLineItem) => void;
  handleDeletePermit: (permit: PermitLineItem) => void;
  handleRestorePermit: (permit: PermitLineItem) => void;
  handleEditPermit: (permit: PermitLineItem) => void;
}

export const PermitTable2 = ({
  handleDeletePermit,
  handleEditPermit,
  handleRestorePermit,
  handleViewPermit,
  loading,
  organisation,
  permits,
  profile,
  project,
  searchTerm,
}: PermitTable2Props) => {
  const colDefs = [
    {
      cellClass: "center-ag-cell",
      cellRenderer: ({ data }: any) => {
        return (
          <Chip
            label={permitStatusToString(data.status, data.archived)}
            sx={{
              backgroundColor: permitStatusToColor(data.status),
              color: "black",
              margin: "auto",
            }}
            size="small"
            variant="filled"
            className="chip"
          />
        );
      },
      cellStyle: centerCellStyles,
      field: "status",
      flex: 3,
      headerClass: "centered-table-header",
      headerName: "Status",
      onCellClicked: (params) => {
        return handleViewPermit(params.data);
      },
    },
    {
      autoHeight: true,
      cellStyle: {
        ...defaultCellConfig.cellStyle,
        textWrap: "auto",
      },
      field: "permitName",
      flex: 4,
      headerName: "Permit Name/Scope",
      onCellClicked: (params) => handleViewPermit(params.data),
      valueFormatter: (params) => params.data.permitName,
    },
    {
      field: "permitType",
      flex: 4,
      headerName: "Permit Type",
      onCellClicked: (params) => handleViewPermit(params.data),
      valueFormatter: (params: any) => {
        return permitTypeToString(params.data.permitType);
      },
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: CollaboratorsActionCell,
      cellRendererParams: {
        paramKey: "approvers",
      },
      cellStyle: centerCellStyles,
      field: "approvers",
      flex: 4,
      headerClass: "centered-table-header",
      headerName: "Approvers",
      onCellClicked: (params) => handleViewPermit(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: CollaboratorsActionCell,
      cellRendererParams: {
        paramKey: "managers",
      },
      cellStyle: centerCellStyles,
      field: "managers",
      flex: 4,
      headerClass: "centered-table-header",
      headerName: "Managers",
      onCellClicked: (params) => handleViewPermit(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  const cellActions = [
    {
      action: handleViewPermit,
      icon: <ViewProjectIcon />,
      title: "View Permit",
    },
    {
      action: handleEditPermit,
      icon: <EditProjectIcon />,
      title: "Edit Permit",
      validateEnabled: (data: PermitLineItem) => {
        return (
          isProjectOwner(project, profile) || isPermitManager(data, profile)
        );
      },
    },
    {
      action: (data: PermitLineItem) => {
        handleDeletePermit(data);
      },
      actionAlt: (data: PermitLineItem) => {
        handleRestorePermit(data);
      },
      icon: <ArchiveProjectIcon />,
      iconAlt: <ReloadIcon />,
      title: "Archive Permit",
      titleAlt: "Restore Permit",
      useAlt: (data: PermitLineItem) => {
        return data.archived;
      },
      validateEnabled: () => {
        return isProjectOwner(project, profile);
      },
    },
  ] as CellAction[];

  return (
    <Grid item xs={12} md={12} mt={2}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={permits}
            searchValue={searchTerm}
            context={{
              cellActions,
              organisation,
              profile,
            }}
            searchKeys={["permitName", "permitType"]}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};

import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomMenu from "@stories/atoms/CustomMenu/CustomMenu";
import CustomMenuItem from "@stories/atoms/CustomMenuItem/CustomMenuItem";
import { QuillInput } from "@stories/atoms/QuillInput/QuillInput";
import Tippy from "@tippyjs/react";
import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { DropdownIcon } from "assets/constants";
import {
  ConditionDocumentLineItem,
  ConditionDocumentStatus,
} from "permit-one-common/src/interfaces/conditionDocument";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { formatDecimalPlaces } from "permit-one-common/src/utils/number";
import { cleanRichText } from "permit-one-common/src/utils/string";
import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editor } from "react-editor";

export const centerCellStyles = {
  alignItems: "center",
  color: "#FF00000",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center !important",
};
export const rightCellStyles = {
  alignItems: "right",
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right",
};

export const defaultCellStyles = {
  alignItems: "left",
  cursor: "pointer",
  display: "flex",
  justifyContent: "left",
  textWrap: "auto",
};

export const defaultCellConfig: ColDef = {
  cellStyle: defaultCellStyles,
  flex: 1,
  resizable: false,
  suppressKeyboardEvent: () => true,
  wrapHeaderText: true,
};

export interface CellAction {
  title: string;
  titleAlt?: string;
  icon: React.ReactNode;
  iconAlt?: React.ReactNode;
  action: (data: any) => Promise<void>;
  actionAlt?: (data: any) => Promise<void>;
  disabled?: boolean;
  adminOnly?: boolean;
  singleAction?: boolean;
  validateEnabled?: (data: any) => boolean;
  useAlt?: (data: any) => boolean;
}
export interface ActionCellProps extends CustomCellRendererProps {
  text: string;
}

export const ActionCellUriToggle = (props: ActionCellProps) => {
  const { context, data, text } = props;

  function handleClick() {
    context.handleAction(data);
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="small"
      disabled={context.loading || !data.uri}
    >
      {text}
    </Button>
  );
};

export const ProgressCell = (props: CustomCellRendererProps) => {
  const progress = props.data.progress ?? props.data.estimatedProgress ?? 0;
  const clampedProgress = Math.min(100, Math.max(0, progress));
  return (
    <>
      <span
        style={{
          fontWeight: 500,
          textAlign: "left", // Aligns text to the right
          width: "50px", // Fixed width for text ensures consistent layout
        }}
      >
        {formatDecimalPlaces(clampedProgress, 0)}%
      </span>

      <LinearProgress
        variant={"determinate"}
        value={clampedProgress}
        sx={{ display: "block", flex: 1 }}
      />
    </>
  );
};

export const AvatarNameCellRenderer = (props: CustomCellRendererProps) => {
  const { firstName, lastName, organisationName, photoUrl } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }} gap={2} ml={1}>
      <Avatar
        src={photoUrl}
        alt={organisationName}
        style={{
          backgroundColor: "#ffffff",
          height: 24,
          marginRight: "8px",
          width: 24,
        }}
        sizes="small"
      />
      <span>
        {firstName} {lastName}
      </span>
    </Box>
  );
};

export const HeaderSubHeaderCell = (props: CustomCellRendererProps) => {
  const { header, subheader } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Typography variant="h6">{header}</Typography>
        <Typography variant="body2">{subheader}</Typography>
      </Stack>
    </Box>
  );
};

export const HeaderCheckboxCell = (props: any) => {
  const handleClick = () => {
    props.context.handleSelectAll();
  };

  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Checkbox
          checked={props.context.allAreSelected}
          onChange={handleClick}
        />
      </Stack>
    </Box>
  );
};

export const CheckboxCell = (props: CustomCellRendererProps) => {
  const { context, data } = props;
  const selected = context?.selectedIds?.includes(data.id) || false;

  const handleCheck = () => {
    props.context.handleCheck(data);
  };

  return (
    <Box id={"123"} sx={{ alignItems: "center", display: "flex" }}>
      <Checkbox checked={selected} onChange={handleCheck} />
    </Box>
  );
};

export const IconActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <TableCell>
      <span
        className={data.archived ? "archivedIcon" : "checkIcon"}
        style={{ boxShadow: "none", height: "12px", width: "12px" }}
      />
    </TableCell>
  );
};

export const ProjectNameActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Stack direction="column" spacing={0}>
      <Typography variant="body2" fontWeight={500} color={"#181D27"}>
        {data.projectName || ""}
      </Typography>
      <Typography variant="caption">{data.projectCode || ""}</Typography>
    </Stack>
  );
};

interface CollaboratorsActionCellProps extends CustomCellRendererProps {
  paramKey: string;
}

export const CollaboratorsActionCell = ({
  data,
  paramKey,
}: CollaboratorsActionCellProps) => {
  const profiles = data[paramKey] || [];
  return profiles.length > 0 ? (
    <AvatarGroup
      max={5}
      sx={{
        "& .MuiAvatar-root": {
          fontSize: "10px",
          height: 23,
          width: 23,
        },
        margin: "auto",
      }}
    >
      {profiles.map((profile: ProfileLineItem, index: number) => {
        return (
          <Tooltip
            key={index}
            title={`${profile.firstName} ${profile.lastName}`}
          >
            <Avatar alt={profile.firstName} src={profile.photoUrl}>
              {profile.firstName.length ? profile.firstName[0] : ""}
            </Avatar>
          </Tooltip>
        );
      })}
    </AvatarGroup>
  ) : (
    <Typography align="center">-</Typography>
  );
};

export const ActionCell = ({
  context: { cellActions, disabled },
  data,
}: ActionCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!cellActions?.length) {
    return <></>;
  }

  if (data.status === ConditionDocumentStatus.Renaming) {
    return (
      <Tooltip title="Renaming...">
        <RotateRightIcon
          sx={{
            "@keyframes spin": {
              "0%": { transform: "rotate(0deg)" },
              "100%": { transform: "rotate(-360deg)" },
            },
            animation: "spin 1s linear infinite",
            display: "inline-block",
          }}
        />
      </Tooltip>
    );
  }

  const enabledButtons = cellActions.filter((action: CellAction) => {
    const activeValue = action.validateEnabled
      ? action.validateEnabled(data)
      : true;
    if (!activeValue) {
      return false;
    }
    return true;
  });

  if (data.isTotalRow || !enabledButtons?.length) {
    return <> </>;
  }

  if (cellActions?.length === 1 && cellActions[0]?.singleAction) {
    const action = cellActions[0];
    return (
      <Button
        sx={{ ":hover": { background: "none" }, color: "#155EEF" }}
        disabled={action.disabled || disabled}
        disableRipple
        variant="text"
        onClick={() => action.action(data)}
      >
        {action.title}
      </Button>
    );
  }

  return (
    cellActions &&
    cellActions.length && (
      <>
        <Box
          sx={{
            alignItem: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <IconButton
            disableRipple
            disabled={disabled}
            onClick={handleClick}
            aria-label="more"
            size="large"
          >
            <DropdownIcon />
          </IconButton>
        </Box>
        <CustomMenu anchorEl={anchorEl} open={open} handleClose={handleClose}>
          {enabledButtons.map(
            (action: CellAction, index: number, arr: CellAction[]) => {
              const useAlt = action.useAlt ? action.useAlt(data) : false;
              return [
                <Box key={`${index}-menu-item`}>
                  <CustomMenuItem
                    action={action}
                    arr={arr}
                    data={data}
                    index={index}
                    setAnchorEl={setAnchorEl}
                    useAlt={useAlt}
                  />
                </Box>,
              ].filter(Boolean);
            }
          )}
        </CustomMenu>
      </>
    )
  );
};

interface ActionCellV2Props {
  cellActions: CellAction[];
  data: ConditionDocumentLineItem;
  disabled: boolean;
}

export const ActionCellV2 = ({
  cellActions,
  data,
  disabled,
}: ActionCellV2Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!cellActions?.length) {
    return <></>;
  }

  if (data.status === ConditionDocumentStatus.Renaming) {
    return (
      <Box
        sx={{
          alignItem: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Tooltip title="Renaming...">
          <RotateRightIcon
            sx={{
              "@keyframes spin": {
                "0%": { transform: "rotate(0deg)" },
                "100%": { transform: "rotate(-360deg)" },
              },
              animation: "spin 1s linear infinite",
              display: "inline-block",
            }}
          />
        </Tooltip>
      </Box>
    );
  }

  const enabledButtons = cellActions.filter((action: CellAction) => {
    const activeValue = action.validateEnabled
      ? action.validateEnabled(data)
      : true;
    if (!activeValue) {
      return false;
    }
    return true;
  });

  if (!enabledButtons?.length) {
    return <> </>;
  }

  if (cellActions?.length === 1 && cellActions[0]?.singleAction) {
    const action = cellActions[0];
    return (
      <Button
        sx={{ ":hover": { background: "none" }, color: "#155EEF" }}
        disabled={action.disabled || disabled}
        disableRipple
        variant="text"
        onClick={() => action.action(data)}
      >
        {action.title}
      </Button>
    );
  }

  return cellActions && cellActions.length ? (
    <>
      <Box
        sx={{
          alignItem: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <IconButton
          disableRipple
          disabled={disabled}
          onClick={handleClick}
          aria-label="more"
          size="large"
        >
          <DropdownIcon />
        </IconButton>
      </Box>
      <CustomMenu anchorEl={anchorEl} open={open} handleClose={handleClose}>
        {enabledButtons.map(
          (action: CellAction, index: number, arr: CellAction[]) => {
            const useAlt = action.useAlt ? action.useAlt(data) : false;
            return [
              <Box key={`${index}-menu-item`}>
                <CustomMenuItem
                  action={action}
                  arr={arr}
                  data={data}
                  index={index}
                  setAnchorEl={setAnchorEl}
                  useAlt={useAlt}
                />
              </Box>,
            ].filter(Boolean);
          }
        )}
      </CustomMenu>
    </>
  ) : (
    <> </>
  );
};

interface RichTextInputCellProps extends CustomCellRendererProps {
  context: {
    canEdit: boolean;
    locked: boolean;
    disabled: boolean;
    handleConditionSave: (data: any) => void;
    paramKey: string;
  };
  paramKey: string;
}

export const RichTextInputCell = ({
  // api,
  context,
  data,
  paramKey,
}: RichTextInputCellProps) => {
  const { canEdit, disabled, handleConditionSave, locked } = context;

  // const [initialRender, setIntialRender] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [value, setValue] = useState(data[paramKey]);
  const displayValue = data ? data[paramKey] : {};
  const editorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {!locked && canEdit && enabled ? (
        <ClickAwayListener onClickAway={() => setEnabled(false)}>
          <Box
            ref={editorRef}
            style={{
              height: "auto",
              overflowY: "visible",
              whiteSpace: "normal",
              width: "100%",
            }}
          >
            <QuillInput
              loading={false}
              disabled={disabled}
              value={value}
              onChange={(value) => setValue(value)}
              onBlur={async () => {
                setValue(value);
                await handleConditionSave({
                  ...data,
                  [paramKey]: cleanRichText(value),
                });
                setEnabled(false);
              }}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <Box
          position="relative"
          style={{
            height: "auto",
            padding: "0px",
            width: "100%",
          }}
        >
          <Box
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "0px 0px",
              width: "100%",
            }}
            onClick={() => setEnabled(true)}
            dangerouslySetInnerHTML={{ __html: displayValue }}
          />
          {!enabled && !locked ? (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                transform: "scale(0.7)",
              }}
            >
              <EditIcon />
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export const RichTextInputCell2 = ({
  context,
  data,
  paramKey,
}: RichTextInputCellProps) => {
  const {
    canEdit,
    disabled,
    // handleRichTextChange,
    // handleRichTextSave,
    locked,
  } = context;

  const displayValue = data ? data[paramKey] : {};
  return (
    <>
      {!locked && canEdit ? (
        <div style={{ height: "auto", width: "100%" }}>
          <Editor
            placeholder="Type message to send..."
            value={data[paramKey]}
          />
          <EditIcon
            style={{
              color: disabled ? "#b4b4b4" : undefined,
              cursor: "pointer",
              height: "20px",
              position: "absolute",
              right: 0,
              top: 0,
              width: "20px",
            }}
          />
        </div>
      ) : (
        <div>
          <div
            style={{
              width: "95%",
            }}
            dangerouslySetInnerHTML={{
              __html: displayValue || "",
            }}
          />
          <LockIcon
            style={{
              color: disabled ? "#b4b4b4" : undefined,
              cursor: "pointer",
              height: "20px",
              position: "absolute",
              right: 0,
              top: 0,
              width: "20px",
            }}
          />
        </div>
      )}
    </>
  );
};

export const PopupCellRenderer = ({
  context,
  data,
  paramKey,
}: RichTextInputCellProps) => {
  const { canEdit, disabled, locked } = context;

  const [value, setValue] = useState(data[paramKey]); // Local state for editing
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);

  const hide = () => {
    // Save data before hiding
    data[paramKey] = value; // Persist the value to `data`
    setVisible(false);
    console.log("Saved value:", value); // Debugging purpose
  };

  const dropDownContent =
    !locked && canEdit ? (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "6px",
          height: "200px",
          width: "200px",
        }}
      >
        <QuillInput
          loading={false}
          disabled={disabled}
          value={value}
          onChange={(newValue) => setValue(newValue)} // Update local state
          onBlur={() => {
            // Save onBlur when the editor loses focus
            data[paramKey] = value;
            console.log("Saved on blur:", value);
          }}
        />
      </div>
    ) : null;

  return (
    <Tippy
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide} // Save when clicking outside
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="left"
    >
      <div
        style={{
          height: "100%",
          overflowY: "auto",
          padding: "8px",
        }}
        onClick={show} // Open dropdown
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </Tippy>
  );
};

interface InputTextFieldProps {
  disabled: boolean;
  data: ConditionDocumentLineItem;
  isSubmitting: boolean;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
}

export const InputTextField = ({
  data,
  disabled,
  isSubmitting,
  updateDocuments,
}: InputTextFieldProps) => {
  const isDisabled = data?.status == ConditionDocumentStatus.Renaming;

  const [value, setValue] = useState(data.documentTitle);

  const handleChangeText = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    []
  );
  const handleSave = useCallback(async (e: FocusEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    await updateDocuments([
      {
        ...data,
        documentTitle: e.target.value,
      },
    ]);
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        variant="standard"
        fullWidth
        value={value}
        onChange={handleChangeText}
        onBlur={handleSave}
        disabled={isSubmitting || disabled || isDisabled}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: "white",
            borderColor: "#D5D7DA",
            fontSize: "14px",
            lineHeight: "20px",
            opacity: "100%",
            outline: "none",
          },
        }}
        sx={{
          "& .MuiInputBase-input": {
            alignItems: "center",
            borderRadius: "0px !important",
            color: "#717680",
            fontFamily: '"Inter", sans-serif',
            fontSize: "14px",
            lineHeight: "20px",
            maxHeight: "52px",
            ml: 1,
            padding: "0px",
          },
          bgcolor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "5px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          padding: "10px 14px 10px 12px",
        }}
      />
    </Box>
  );
};

interface DropDownFieldProps extends CustomCellRendererProps {
  context: {
    canEdit: boolean;
    disabled: boolean;
    locked: boolean;
    updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  };
  paramKey: string;
  label: string;
  placeholder: string;
  options: any[];
}

export const DropDownField = ({
  context,
  data,
  options,
  paramKey,
}: DropDownFieldProps) => {
  const { updateDocuments } = context;

  const isDisabled = data?.status == ConditionDocumentStatus.Renaming;

  const [text, setText] = useState(data[paramKey]);

  const handleSaveText = useCallback(
    async (e: SelectChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
      await updateDocuments([
        {
          ...data,
          [paramKey]: e.target.value,
        },
      ]);
    },
    []
  );
  return (
    <FormControl fullWidth>
      <Select
        value={text}
        disabled={isDisabled}
        onChange={(e: SelectChangeEvent<HTMLInputElement>) => {
          handleSaveText(e);
        }}
        MenuProps={{
          MenuListProps: {
            sx: {
              maxHeight: 200, // Apply max height to the list directly as well
            },
          },
          PaperProps: {
            sx: {
              maxHeight: 200, // Set the maximum height for the menu
              overflowY: "auto", // Allow scrolling if the menu exceeds the height
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface RichTextConditionCellProps {
  value: string;
  locked: boolean;
  onChange: (value: string) => void;
  onClick: () => void;
  finalised: boolean;
}

export const RichTextConditionCell = ({
  finalised,
  locked,
  onChange,
  onClick,
  value,
}: RichTextConditionCellProps) => {
  const [enabled, setEnabled] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const onClose = () => {
    setEnabled(false);
  };

  useEffect(() => {
    if (locked) {
      setEnabled(false);
    }
  }, [locked]);

  return (
    <TableCell
      sx={{
        borderBottom: "2px solid rgba(241, 243, 244, 1)",
        borderTop: "2px solid rgba(241, 243, 244, 1)",
        padding: "0px 5px",
        verticalAlign: "top",
      }}
      onClick={() => (locked ? onClick() : setEnabled(true))}
    >
      {finalised || !enabled || locked ? (
        <Box
          position="relative"
          alignItems={"start"}
          sx={{
            height: "auto",
            padding: "0px",
            width: "100%",
          }}
          className="quill-container"
        >
          <Box
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "0px 0px",
              width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: currentValue }}
          />
        </Box>
      ) : (
        <QuillInput
          loading={false}
          disabled={locked}
          value={currentValue}
          onChange={(value) => {
            return setCurrentValue(() => value);
          }}
          onClose={() => {
            return onClose();
          }}
          onBlur={() => onChange(currentValue)}
        />
      )}
    </TableCell>
  );
};

interface TextConditionCellProps {
  value: string;
  locked: boolean;
  onChange: (value: string) => void;
  onClick: () => void;
  finalised: boolean;
}

export const TextConditionCell = ({
  finalised,
  locked,
  onChange,
  onClick,
  value,
}: TextConditionCellProps) => {
  const [enabled, setEnabled] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (locked) {
      setEnabled(false);
    }
  }, [locked]);

  return (
    <TableCell
      sx={{
        borderBottom: "2px solid rgba(241, 243, 244, 1)",
        borderTop: "2px solid rgba(241, 243, 244, 1)",
        padding: "0px 5px",
        verticalAlign: "top",
      }}
      onClick={() => (locked ? onClick() : setEnabled(true))}
    >
      {finalised || !enabled || locked ? (
        <Box
          position="relative"
          alignItems={"start"}
          sx={{
            height: "auto",
            padding: "0px",
            width: "100%",
          }}
          className="quill-container"
        >
          <Box
            sx={{
              fontSize: "13px",
              height: "100%",
              overflowY: "auto",
              padding: "0px 0px",
              whiteSpace: "pre-wrap",
              width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: currentValue }}
          />
        </Box>
      ) : (
        <TextField
          disabled={locked}
          value={currentValue}
          multiline
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "13px",
              fontWeight: "400",
              padding: "4px 6px",
            },
            "& .MuiInputBase-root": {
              padding: "0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            "& .MuiOutlinedInput-root:focus-within": {
              border: "none !important",
              boxShadow: "none !important",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            padding: "4px 6px",
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            return setCurrentValue(() => value);
          }}
          onBlur={() => {
            onChange(currentValue);
            setEnabled(false);
          }}
        />
      )}
    </TableCell>
  );
};

export const StatusCellRenderer = (props: CustomCellRendererProps) => {
  const isLoading = props.data?.status == ConditionDocumentStatus.Renaming;

  return (
    <Box
      sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
    >
      {isLoading ? (
        <Tooltip title="Renaming...">
          <RotateRightIcon
            sx={{
              "@keyframes spin": {
                "0%": { transform: "rotate(0deg)" },
                "100%": { transform: "rotate(-360deg)" },
              },
              animation: "spin 1s linear infinite",
              display: "inline-block",
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Complete">
          <CheckCircleIcon color="success" />
        </Tooltip>
      )}
    </Box>
  );
};

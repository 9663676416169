import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useDirectory } from "@hooks/crud/useDirectory";
import { Parser } from "@json2csv/plainjs";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { DirectoryTable2 } from "@stories/organisms/Tables/DirectoryTable/DirectoryTable2";
import { ExportIcon } from "assets/constants";
import { camelCaseToWords } from "permit-one-common/src/utils/string";
import { useState } from "react";

export const Directory = (): JSX.Element => {
  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isAuthProfileLoading } = useProfileContext();

  const { directory, isDirectoryLoading } = useDirectory(selectedProject?.id);
  // const { createProfiles, isProfilesLoading } = useProfile();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };
  const loading =
    isProjectLoading || isAuthProfileLoading || isDirectoryLoading;

  const downloadCSV = async () => {
    const opts = {
      fields: [
        {
          label: "Name",
          value: "name",
        },
        {
          label: "Organisation Name",
          value: "organisationName",
        },
        {
          label: "Job Title",
          value: "jobTitle",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Phone Number",
          value: "phoneNumber",
        },
      ],
    };
    const parser = new Parser(opts);
    const csvString = parser.parse(directory);

    const exportedFilenmae = "permit-one-directory.csv";

    const formattedString = camelCaseToWords(csvString);
    const blob = new Blob([formattedString], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: downloadCSV,
      icon: <ExportIcon variant="secondary" />,
      text: "Download Excel",
      type: HeaderButtonType.PRIMARY,
      width: 160,
    },
  ];
  const tabs = [
    {
      label: "All",
    },
    {
      label: "My Organisation",
    },
    {
      label: "Collaborators",
    },
  ];

  const filteredDirectory = directory.filter((contact) => {
    if (tabIndex === 0) {
      return true;
    }
    if (tabIndex === 1) {
      return contact.jobTitle !== "Outside Collaborator";
    }
    if (tabIndex === 2) {
      return contact.jobTitle === "Outside Collaborator";
    }
    return false;
  });

  return (
    <PageContainer>
      <Header
        subTitle={selectedProject?.projectName || "PermitOne Permits"}
        mainTitle={"Project Contacts"}
        loading={loading}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        buttons={buttons}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        tabs={tabs}
      />
      <DirectoryTable2
        loading={loading}
        directory={filteredDirectory}
        searchTerm={searchTerm}
      />
    </PageContainer>
  );
};

import { API } from "aws-amplify";
import {
  ApiBatchRequest,
  ApiBatchResponse,
  ApiListResponse,
  ApiRequest,
  ApiResponse,
} from "permit-one-common/src/interfaces/api";

type APIRequest = {
  response: true;
  body?: { [key: string]: any };
  queryStringParameters?: { [key: string]: any };
};

const API_NAME = "api";

export const getApiData = async (
  path: string,
  callFn: string,
  id: string,
  values: { [key: string]: number | string | Date | undefined } = {}
): Promise<ApiResponse> => {
  const myInit = {
    queryStringParameters: {
      callFn,
      id,
      path,
      ...values,
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  } as APIRequest;
  const res = await API.get(API_NAME, "api", myInit);
  return res as ApiResponse;
};

export const listApiData = async (
  path: string,
  callFn: string,
  id: string,
  values: { [key: string]: number | string | Date | undefined | string[] } = {}
): Promise<ApiListResponse> => {
  const myInit = {
    queryStringParameters: {
      callFn,
      id,
      path,
      ...values,
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  } as APIRequest;
  const res = await API.get(API_NAME, "api", myInit);
  return res as ApiListResponse;
};

export const postApiData = async (
  path: string,
  callFn: string,
  requestData: ApiRequest
): Promise<ApiResponse> => {
  const myInit = {
    body: {
      callFn,
      path,
      ...requestData,
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  } as APIRequest;
  const res = await API.post(API_NAME, "api", myInit);
  return res as ApiResponse;
};

export const postBatchApiData = async (
  path: string,
  callFn: string,
  requestData: ApiBatchRequest
): Promise<ApiBatchResponse> => {
  const myInit = {
    body: {
      callFn,
      data: requestData,
      path,
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  } as APIRequest;
  const res = await API.post(API_NAME, "api", myInit);
  return res as ApiBatchResponse;
};

export const deleteBatchApiData = async (
  path: string,
  callFn: string,
  requestData: ApiBatchRequest
): Promise<ApiResponse> => {
  const myInit = {
    body: {
      callFn,
      data: requestData,
      path,
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  } as APIRequest;
  const res = await API.del(API_NAME, "api", myInit);
  return res as ApiResponse;
};

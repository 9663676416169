import { listApiData, postApiData, postBatchApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import {
  decodeConditionDocument,
  encodeConditionDocument,
} from "permit-one-common/src/decoders/conditionDocument";
import {
  ConditionDocument,
  ConditionDocumentLineItem,
  ConditionDocumentStatus,
} from "permit-one-common/src/interfaces/conditionDocument";
import { useCallback, useEffect, useState } from "react";

export const useDocument = (
  projectId?: string,
  permitId?: string,
  conditionIds?: string[]
) => {
  const [documents, setDocuments] = useState<ConditionDocumentLineItem[]>([]);
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(true);
  const [isDocumentUpdating, setIsDocumentUpdating] = useState<boolean>(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const listDocumentsForCondition = async (
    conditionIds: string[]
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);
      const documentResult = await listApiData(
        "listConditionDocuments",
        "conditionDocument",
        "",
        { conditionIds: JSON.stringify(conditionIds) }
      );
      const documentLineItems = await Promise.all(
        documentResult.data.map((c) =>
          decodeConditionDocument(c as ConditionDocument, getImageUrl)
        )
      );
      console.log(documentLineItems);
      setDocuments(documentLineItems);
    } catch (e: any) {
      console.log(e);
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const listDocumentsForPermit = async (projectId: string): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const documentRes = await listApiData(
        "listConditionDocumentsForPermit",
        "conditionDocument",
        projectId
      );
      const documentLineItems = await Promise.all(
        documentRes.data.map((c) =>
          decodeConditionDocument(c as ConditionDocument, getImageUrl)
        )
      );

      setDocuments(documentLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const listDocumentsForProject = async (projectId: string): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const documentRes = await listApiData(
        "listConditionDocumentsForProject",
        "conditionDocument",
        projectId
      );
      const documentLineItems = await Promise.all(
        documentRes.data.map((c) =>
          decodeConditionDocument(c as ConditionDocument, getImageUrl)
        )
      );

      setDocuments(documentLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const createDocuments = async (
    documentsToCreate: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentUpdating(true);

      const encodedDocuments = documentsToCreate.map((d) =>
        encodeConditionDocument(d)
      );
      const response = await postBatchApiData(
        "createConditionDocuments",
        "conditionDocument",
        encodedDocuments
      );
      const decodedDocuments = await Promise.all(
        response.data.map((d) =>
          decodeConditionDocument(d as never as ConditionDocument, getImageUrl)
        )
      );

      setDocuments([...documents, ...decodedDocuments]);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentUpdating(false);
    }
  };

  const assignDocumentsToConditions = async (
    conditionDocumentsToAssign: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentUpdating(true);

      const encodedDocuments = conditionDocumentsToAssign.map((d) =>
        encodeConditionDocument(d)
      );
      await postBatchApiData(
        "upsertConditionDocumentConditions",
        "conditionDocument",
        encodedDocuments
      );
      setDocuments([...documents, ...conditionDocumentsToAssign]);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentUpdating(false);
    }
  };

  const unassignDocumentsToConditions = async (
    conditionDocumentsToAssign: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentUpdating(true);

      const encodedDocuments = conditionDocumentsToAssign.map((d) =>
        encodeConditionDocument(d)
      );
      await postBatchApiData(
        "upsertConditionDocumentConditions",
        "conditionDocument",
        encodedDocuments
      );

      const documentIdsToDelete = conditionDocumentsToAssign.map((d) => d.id);
      setDocuments(
        documents.filter((p) => !documentIdsToDelete.includes(p.id))
      );
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentUpdating(false);
    }
  };

  const updateDocuments = async (
    documentsToUpdate: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentUpdating(true);
      const encodedDocuments = documentsToUpdate.map((d) =>
        encodeConditionDocument(d)
      );

      const response = await postBatchApiData(
        "updateConditionDocuments",
        "conditionDocument",
        encodedDocuments
      );

      const decodedDocuments = await Promise.all(
        response.data.map((d) =>
          decodeConditionDocument(d as never as ConditionDocument, getImageUrl)
        )
      );

      setDocuments(
        documents.map((c) => {
          const matchingDocument = decodedDocuments.find((d) => d.id === c.id);
          if (matchingDocument) {
            return matchingDocument;
          }
          return c;
        })
      );
    } catch (e: any) {
      setError("Could not update document");
    } finally {
      setIsDocumentUpdating(false);
    }
  };

  const deleteDocument = async (
    document: ConditionDocumentLineItem
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);
      await postApiData(
        "deleteConditionDocument",
        "conditionDocument",
        encodeConditionDocument(document)
      );
      setDocuments(documents.filter((p) => p.id !== document.id));
    } catch (e: any) {
      setError("Could not delete document");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const pollDocuments = useCallback(async (): Promise<void> => {
    let counter = 0;
    let documentLineItems: ConditionDocumentLineItem[] = [];

    const renamingDocs = documents.some(
      (d: any) => d.status === ConditionDocumentStatus.Renaming
    );
    if (!isPolling && renamingDocs) {
      setIsPolling(true);
      try {
        while (counter < 300) {
          const documentResult = await listApiData(
            "pollConditionDocuments",
            "conditionDocument",
            "",
            {
              documentIds: JSON.stringify(
                documents
                  .filter((d) => d.status === ConditionDocumentStatus.Renaming)
                  .map((d) => d.id)
              ),
            }
          );

          documentLineItems = await Promise.all(
            documentResult.data.map((c) =>
              decodeConditionDocument(c as ConditionDocument, getImageUrl)
            )
          );

          if (
            !documentLineItems.some(
              (d: any) => d.status === ConditionDocumentStatus.Renaming
            )
          ) {
            break;
          }

          await new Promise((r) => setTimeout(r, 2500));
          counter++;
        }
        setDocuments(() => [...documentLineItems]);
      } catch (e: any) {
        setError("Could not list documents");
      } finally {
        setIsPolling(false);
      }
    }
  }, [isPolling, documents]);

  useEffect(() => {
    if (conditionIds && conditionIds.length > 0) {
      listDocumentsForCondition(conditionIds);
    } else if (permitId) {
      listDocumentsForPermit(permitId);
    } else if (projectId) {
      listDocumentsForProject(projectId);
    } else {
      setIsDocumentLoading(false);
    }
  }, [projectId, permitId, conditionIds]);

  return {
    assignDocumentsToConditions,
    createDocuments,
    deleteDocument,
    documents,
    error,
    isDocumentLoading,
    isDocumentUpdating,
    pollDocuments,
    unassignDocumentsToConditions,
    updateDocuments,
  };
};

import { Box } from "@mui/material";
import {
  ColDef,
  ColGroupDef,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { useEffect, useState } from "react";
import "./style.css";

import CustomLoadingOverlay from "./CustomLoadingOverlay";
import { defaultCellConfig } from "./TableCells";
import TableSkeleton from "./TableSkeleton";

type TableProps<T extends object = any> = {
  columnDefs: (ColDef | ColGroupDef)[];
  data: T[];
  loading: boolean;
  searchKeys?: (keyof T)[];
  context?: any;
  pinnedBottomRowData?: any[];
  onCellValueChanged?: (params: any) => void;
  searchValue?: string;
  autoSizeStrategy?: SizeColumnsToContentStrategy;
  rowHeight?: number;
  noFullTableBorder?: boolean;
};

const containerStyles = (noFullTableBorder: boolean) => ({
  backgroundColor: "#fff",
  border: `${noFullTableBorder ? "none" : "1px solid #E9EAEB"}`,
  borderBottomLeftRadius: `${noFullTableBorder ? "12px" : "12px"}`,
  borderBottomRightRadius: `${noFullTableBorder ? "12px" : "12px"}`,
  borderTop: `${noFullTableBorder ? "1px solid #E9EAEB" : "1px solid #E9EAEB"}`,
  borderTopLeftRadius: `${noFullTableBorder ? "0" : "12px"}`,
  borderTopRightRadius: `${noFullTableBorder ? "0" : "12px"}`,
  boxShadow: "0px 1px 2px 0px #0A0D120D",
  overflowX: "auto",
  padding: "0",
});

export const isTotalRow = (params: any) =>
  params.data?.itemDescription === "Total Local Content";

export const getCellStyle = (params: any, textAlign = "center") => ({
  backgroundColor: isTotalRow(params) ? "#f4f4f4" : "white",
  fontWeight: isTotalRow(params) ? "bold" : "normal",
  textAlign: isTotalRow(params) && textAlign === "right" ? "right" : textAlign,
});

export function filterByKeys<T>(
  data: T[],
  keys: (keyof T)[],
  columnDefs: ColDef[]
) {
  return function (value: string) {
    const formatted = value.trim().toLocaleLowerCase();

    if (formatted.length < 2) {
      return data;
    }

    return data.filter((item) => {
      return keys.some((key) => {
        const colDef = columnDefs.find((col) => col.field === key);
        if (
          colDef?.valueFormatter &&
          typeof colDef.valueFormatter === "function"
        ) {
          const formattedValue = colDef.valueFormatter({
            data: { [key]: item[key] },
          } as any);
          return (
            typeof formattedValue === "string" &&
            formattedValue
              .toLowerCase()
              .trim()
              .includes(formatted.toLowerCase())
          );
        }
        return typeof item[key] === "string"
          ? (item[key] as string)
              .toLowerCase()
              .trim()
              .includes(formatted.toLowerCase())
          : false;
      });
    });
  };
}

export const generateTableHead = (colDefs: ColDef[]): TableHeaderCell[] => {
  const totalFlex = colDefs.reduce((sum, col) => sum + (col.flex || 1), 0);

  return colDefs.map((col) => {
    const widthPercentage = col.flex
      ? `${((col.flex / totalFlex) * 100).toFixed(0)}%`
      : "auto";

    let align: "left" | "center" | "right" | undefined = "left";

    if (col.headerClass) {
      if (col.headerClass === "centered-table-header") {
        align = "center";
      }
    }

    if (
      !col.headerClass &&
      col.cellStyle &&
      typeof col.cellStyle === "object"
    ) {
      align =
        (col.cellStyle["textAlign"] as "left" | "center" | "right") || "left";
    }

    return {
      action: col.field === "action" ? true : false,
      align,
      id: col.field || "",
      label: "column name",
      width: widthPercentage,
    };
  });
};

export function Table<T extends object = any>({
  autoSizeStrategy,
  columnDefs,
  context,
  data,
  loading,
  noFullTableBorder = false,
  onCellValueChanged,
  pinnedBottomRowData,
  rowHeight,
  searchKeys = [],
  searchValue = "",
}: TableProps<T>) {
  const searchFilter = filterByKeys(data, searchKeys, columnDefs);

  const rows = searchFilter(searchValue);

  const [gridKey, setGridKey] = useState<number>(0);

  useEffect(() => {
    setGridKey((prevKey: number) => prevKey + 1); // Increment key to reinitialize grid
  }, [context]);

  return (
    <Box sx={containerStyles(noFullTableBorder)}>
      <Box className="ag-theme-quartz">
        {loading ? (
          <TableSkeleton colDefs={columnDefs} />
        ) : (
          <AgGridReact
            key={gridKey}
            loading={loading}
            headerHeight={40}
            rowHeight={rowHeight || 60}
            suppressRowTransform
            suppressAnimationFrame
            autoSizeStrategy={autoSizeStrategy}
            suppressRowDrag
            suppressMovableColumns
            overlayLoadingTemplate={""}
            overlayNoRowsTemplate={""}
            suppressCellFocus
            suppressServerSideFullWidthLoadingRow
            rowData={rows}
            columnDefs={columnDefs}
            animateRows={false}
            defaultColDef={{
              ...defaultCellConfig,
            }}
            domLayout="autoHeight"
            components={{
              customLoadingOverlay: CustomLoadingOverlay,
            }}
            gridOptions={{
              context: {
                ...context,
              },
            }}
            selectionColumnDef={{
              suppressHeaderKeyboardEvent: () => true,
              suppressKeyboardEvent: () => true,
              suppressNavigable: true,
            }}
            stopEditingWhenCellsLoseFocus
            onCellValueChanged={onCellValueChanged}
            pinnedBottomRowData={pinnedBottomRowData}
            loadingOverlayComponent={"customLoadingOverlay"}
            getRowClass={(params) => {
              return params.data.isTotalRow ? "total-row" : "";
            }}
          />
        )}
      </Box>
    </Box>
  );
}

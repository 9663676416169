import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useFormik } from "formik";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import {
  createDefaultProfile,
  ProfileLineItem,
} from "permit-one-common/src/interfaces/profile";
import { createDefaultUser } from "permit-one-common/src/interfaces/user";
import { ChangeEvent } from "react";
import * as yup from "yup";

interface UserFormProps {
  isLoading: boolean;
  organisation?: OrganisationLineItem;
  profiles: ProfileLineItem[];
  existingUser?: ProfileLineItem;
  handleSubmit: (user: ProfileLineItem) => void;
}

export const UserForm = ({
  existingUser,
  handleSubmit,
  isLoading,
  organisation,
}: UserFormProps) => {
  const validationSchema = yup.object({
    admin: yup.boolean().required(),
    email: yup.string().email().required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    jobTitle: yup.string().required("Job Title is required"),
    lastName: yup.string().required("Last Name is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      admin: existingUser?.isAdmin || false,
      email: existingUser?.email || "",
      firstName: existingUser?.firstName || "",
      jobTitle: existingUser?.jobTitle || "",
      lastName: existingUser?.lastName || "",
      phoneNumber: existingUser?.phoneNumber || "",
    },
    onSubmit: (values: any) => {
      if (organisation) {
        if (existingUser) {
          handleSubmit({
            ...existingUser,
            firstName: values.firstName,
            isAdmin: values.admin,
            jobTitle: values.jobTitle,
            lastName: values.lastName,
            organisationId: organisation.id,
            phoneNumber: values.phoneNumber,
          });
        } else {
          const newUser = createDefaultUser(values.email);
          const newProfile = createDefaultProfile(newUser);
          handleSubmit({
            ...newProfile,
            firstName: values.firstName,
            invited: true,
            isAdmin: values.admin,
            jobTitle: values.jobTitle,
            lastName: values.lastName,
            organisationId: organisation.id,
            phoneNumber: values.phoneNumber,
          });
        }
      }
    },
    validationSchema: validationSchema,
  });

  const loading = isLoading || formik.isSubmitting;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <TextField
            id="email"
            name="email"
            label="Email"
            margin="dense"
            fullWidth
            disabled={
              formik.isSubmitting || loading || existingUser !== undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ""}
            value={formik.values.email}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id="jobTitle"
            name="jobTitle"
            label="Job Title"
            margin="dense"
            disabled={
              formik.isSubmitting || loading || existingUser !== undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            helperText={formik.touched.jobTitle ? formik.errors.jobTitle : ""}
            value={formik.values.jobTitle}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            fullWidth
            margin="dense"
            disabled={
              formik.isSubmitting || loading || existingUser !== undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName ? formik.errors.firstName : ""}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            margin="dense"
            disabled={
              formik.isSubmitting || loading || existingUser !== undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            fullWidth
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched?.lastName ? formik.errors.lastName : ""}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            margin="dense"
            disabled={
              formik.isSubmitting || loading || existingUser !== undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={
              formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} />
        <Grid item xs={6} sm={6}>
          <FormControlLabel
            id={"admin"}
            name={"admin"}
            defaultChecked={formik.values.admin}
            disabled={formik.isSubmitting || loading}
            control={
              <Checkbox
                disabled={
                  formik.isSubmitting || loading || existingUser !== undefined
                }
                checked={formik.values.admin}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("admin", e.target.checked);
                }}
              />
            }
            label="Is Admin"
          />
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton
            variant="contained"
            type="submit"
            disabled={loading}
            loading={formik.isSubmitting || loading}
          >
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};

import KeyIcon from "@mui/icons-material/Key";
import KeyIconOff from "@mui/icons-material/KeyOff";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Paper,
  SpeedDial,
  SpeedDialProps,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import CustomMenu from "@stories/atoms/CustomMenu/CustomMenu";
import CustomMenuItem from "@stories/atoms/CustomMenuItem/CustomMenuItem";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { CellAction } from "@stories/organisms/Table/TableCells";
import { ConditionTableSkeleton } from "@stories/organisms/Table/TableSkeleton";
import { AddIcon, DropdownIcon, RejectIcon } from "assets/constants";
import { AnimatePresence, motion } from "framer-motion";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";

const AnimatedTableCell = motion(TableCell);
interface StyledSpeedDialProps extends SpeedDialProps {
  variant?: string;
}

const StyledSpeedDial = styled(SpeedDial)<StyledSpeedDialProps>(
  ({ theme, variant }) => ({
    "& .MuiSpeedDial-fab": {
      "&:hover": {
        backgroundColor: variant ? "darkred" : undefined, // Optional: Change color on hover
      },
      backgroundColor: variant ? "red" : undefined, // Set the background color to red
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      left: theme.spacing(2),
      top: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    position: "absolute",
  })
);

interface ConditionTableHeaderRowProps {
  locked: boolean;
  allSelected: boolean;
  handleSelectAll: () => void;
}

const ConditionTableHeaderRow = ({
  allSelected,
  handleSelectAll,
  locked,
}: ConditionTableHeaderRowProps) => {
  return (
    <TableHead>
      <TableRow sx={{ borderLeft: "0px", height: "45px" }}>
        <AnimatePresence>
          {!locked ? (
            <AnimatedTableCell
              sx={{
                backgroundColor: "#fff",
                borderBottom: "2px solid rgba(241, 243, 244, 1)",
                borderTop: "2px solid rgba(241, 243, 244, 1)",
                padding: "0px 5px",
                textAlign: "center",
                width: "5%",
              }}
              layout // Enable layout transition
              align="center"
              component="th"
              initial={{ opacity: 0, x: -20 }} // Enter from the left
              animate={{ opacity: 1, x: 0 }} // Set to the default position
              exit={{ opacity: 0, x: -20 }} // Exit to the left
              transition={{
                damping: 25,
                stiffness: 200,
                type: "spring",
              }}
            >
              <Checkbox
                checked={allSelected}
                onChange={() => {
                  handleSelectAll();
                }}
              />
            </AnimatedTableCell>
          ) : null}
        </AnimatePresence>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            border: "2px solid rgb(233, 234, 235)",
            maxWidth: "5%",
            padding: "0px 5px",
            width: "5%",
          }}
          align="center"
        >
          #
        </TableCell>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            border: "2px solid rgb(233, 234, 235)",
            maxWidth: "30%",
            padding: "0px 5px",
            width: "30%",
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              fontFamily={"Inter, Sans-serif"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"16px"}
              color={"#717f76"}
            >
              Details
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          component="th"
          sx={{
            alignItems: "start",
            backgroundColor: "#fff",
            border: "2px solid rgb(233, 234, 235)",
            maxWidth: "30%",
            padding: "0px 5px",
            width: "30%",
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              fontFamily={"Inter, Sans-serif"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"16px"}
              color={"#717f76"}
            >
              Comments
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            border: "2px solid rgb(233, 234, 235)",
            maxWidth: "20%",
            padding: "0px 5px",
            width: "10%",
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              fontFamily={"Inter, Sans-serif"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"16px"}
              color={"#717f76"}
            >
              Discipline
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            border: "2px solid rgb(233, 234, 235)",
            maxWidth: "10%",
            padding: "0px 5px",
            width: "10%",
          }}
          align="center"
        >
          <Typography
            fontFamily={"Inter, Sans-serif"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#717f76"}
          >
            Status
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: "#fff",
            border: "2px solid rgb(233, 234, 235)",
            maxWidth: "5%",
            padding: "0px 5px",
            width: "5%",
          }}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
};

interface ConditionTableRowProps {
  locked: boolean;
  readOnly: boolean;
  data: ConditionLineItem;
  selectedConditions: ConditionLineItem[];
  onClick: (condition: ConditionLineItem) => void;
  handleUpdateCondition: (condition: ConditionLineItem) => Promise<void>;
  handleOpenConditionDocumentModal: (
    condition: ConditionLineItem
  ) => Promise<void>;
  handleSelectCondition: (condition: ConditionLineItem) => void;
}
const ConditionTableRow = ({
  data,
  handleOpenConditionDocumentModal,
  handleSelectCondition,
  handleUpdateCondition,
  locked,
  onClick,
  readOnly,
  selectedConditions,
}: ConditionTableRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRejectAndResubmit = async (condition: ConditionLineItem) => {
    await handleUpdateCondition({
      ...condition,
      status: ConditionStatus.Assigned,
    });
  };

  const cellActions: CellAction[] = readOnly
    ? []
    : [
        {
          action: handleOpenConditionDocumentModal,
          icon: <AddIcon />,
          title: "Add Documents",
        },
        {
          action: handleRejectAndResubmit,
          icon: <RejectIcon />,
          title: "Reject & Resubmit",
          validateEnabled: () => {
            return (
              data.status === ConditionStatus.InReview ||
              data.status === ConditionStatus.Approved
            );
          },
        },
      ];

  const enabledButtons = cellActions.filter((action: CellAction) => {
    const activeValue = action.validateEnabled
      ? action.validateEnabled(data)
      : true;
    if (!activeValue) {
      return false;
    }
    return true;
  });

  const checked = selectedConditions.includes(data);

  return (
    <TableRow
      sx={{
        cursor: locked ? "pointer" : "default",
      }}
    >
      <AnimatePresence>
        {!locked ? (
          <AnimatedTableCell
            sx={{
              borderBottom: "2px solid rgba(241, 243, 244, 1)",
              borderTop: "2px solid rgba(241, 243, 244, 1)",
              maxWidth: "5%",
              width: "5%",
            }}
            align="center"
            component="td"
            initial={{ opacity: 0, x: -20 }} // Enter from the left
            animate={{ opacity: 1, x: 0 }} // Set to the default position
            exit={{ opacity: 0, x: -20 }} // Exit to the left
            transition={{
              damping: 25,
              stiffness: 200,
              type: "spring",
            }}
            layout // Enable layout transition
          >
            <Checkbox
              checked={checked}
              onChange={() => {
                handleSelectCondition(data);
              }}
            />
          </AnimatedTableCell>
        ) : null}
      </AnimatePresence>
      <TableCell
        align="center"
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
        }}
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      >
        {conditonNameToString(data.conditionAuthority, data.permitItemNumber)}
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
          padding: "0px 5px",
          verticalAlign: "top",
        }}
        onClick={() => (locked ? onClick(data) : undefined)}
      >
        <Box
          position="relative"
          alignItems={"start"}
          sx={{
            height: "auto",
            padding: "0px",
            width: "100%",
          }}
          className="quill-container"
        >
          <Box
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "0px 0px",
              width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: data?.conditionDetails || "" }}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
          padding: "0px 5px",
          verticalAlign: "top",
        }}
        onClick={() => (data ? onClick(data) : undefined)}
      >
        <Box
          position="relative"
          alignItems={"start"}
          sx={{
            height: "auto",
            padding: "0px",
            width: "100%",
          }}
          className="quill-container"
        >
          <Box
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "0px 0px",
              width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: data.conditionComments || "" }}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
          padding: "0px 5px",
          verticalAlign: "top",
        }}
        onClick={() => (locked ? onClick(data) : undefined)}
      >
        <Box
          position="relative"
          alignItems={"start"}
          sx={{
            height: "auto",
            padding: "0px",
            width: "100%",
          }}
          className="quill-container"
        >
          <Box
            sx={{
              fontSize: "13px",
              height: "100%",
              overflowY: "auto",
              padding: "0px 0px",
              width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: data?.discipline || "" }}
          />
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
        }}
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      >
        <Chip
          label={conditionStatusToString(
            data.status,
            Boolean(data.markedAsNotApplicable)
          )}
          sx={{
            backgroundColor: conditionStatusToColor(
              data.status,
              Boolean(data.markedAsNotApplicable)
            ),

            color: "black",
            cursor: locked ? "pointer" : "default",
            margin: "auto",
          }}
          size="small"
          variant="filled"
          className="chip"
        />
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
        }}
      >
        {enabledButtons.length && (
          <>
            <Box
              sx={{
                alignItem: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <IconButton
                onClick={handleClick}
                disableRipple
                aria-label="more"
                size="large"
              >
                <DropdownIcon />
              </IconButton>
            </Box>
            <CustomMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
            >
              {enabledButtons.map(
                (action: CellAction, index: number, arr: CellAction[]) => {
                  const useAlt = action.useAlt ? action.useAlt(data) : false;
                  return [
                    <Box key={`${index}-menu-item`}>
                      <CustomMenuItem
                        action={action}
                        arr={arr}
                        data={data}
                        index={index}
                        setAnchorEl={setAnchorEl}
                        useAlt={useAlt}
                      />
                    </Box>,
                  ].filter(Boolean);
                }
              )}
            </CustomMenu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
interface ConditionTable3Props {
  loading: boolean;
  readOnly: boolean;
  canEdit: boolean;
  conditions: ConditionLineItem[];
  handleChange: (condition: ConditionLineItem) => void;
  handleClick: (condition: ConditionLineItem) => void;
  setLocked: (value: boolean) => void;
  handleOpenConditionDocumentModal: (
    condition: ConditionLineItem
  ) => Promise<void>;
  locked: boolean;
  selectedConditions: ConditionLineItem[];
  handleSelectAll: () => void;
  handleSelectCondition: (condition: ConditionLineItem) => void;
  allSelected: boolean;
}

export const PublicConditionTable3 = ({
  allSelected,
  conditions,
  handleChange,
  handleClick,
  handleOpenConditionDocumentModal,
  handleSelectAll,
  handleSelectCondition,
  loading,
  locked,
  readOnly,
  selectedConditions,
  setLocked,
}: ConditionTable3Props) => {
  const memoisedHeader = useMemo(() => {
    return (
      <ConditionTableHeaderRow
        locked={locked}
        handleSelectAll={handleSelectAll}
        allSelected={allSelected}
      />
    );
  }, [locked, allSelected]);

  const memoisedRows = useMemo(() => {
    return conditions.map((data, index: number) => (
      <ConditionTableRow
        key={index}
        data={data}
        locked={locked}
        onClick={handleClick}
        selectedConditions={selectedConditions}
        handleUpdateCondition={async (condition: ConditionLineItem) =>
          handleChange(condition)
        }
        handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
        readOnly={readOnly}
        handleSelectCondition={handleSelectCondition}
      />
    ));
  }, [conditions, locked]);

  return (
    <Box mt={2}>
      {loading ? (
        <ConditionTableSkeleton />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            {memoisedHeader}
            <TableBody>
              {memoisedRows}
              {!conditions.length ? (
                <NoRows title={"Conditions"} colSpan={6} />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!readOnly ? (
        <Box sx={{ bottom: 16, position: "absolute", right: 16 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ bottom: 16, position: "absolute", right: 16 }}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={locked ? "Unlock Checklist" : "Lock Checklist"}
            >
              <StyledSpeedDial
                ariaLabel="checklist-lock"
                onClick={() => {
                  const newLocked = !locked;
                  setLocked(newLocked);
                }}
                open={!locked}
                icon={locked ? <KeyIcon /> : <KeyIconOff />}
                direction={"up"}
                sx={{ position: "static" }}
              />
            </Tooltip>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

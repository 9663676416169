import { Box, Paper, Table, TableBody, TableContainer } from "@mui/material";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ConditionTableSkeleton } from "@stories/organisms/Table/TableSkeleton";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useMemo } from "react";

import "react-quill/dist/quill.snow.css";
import { ConditionTableHeaderRow } from "./ConditionTable3HeaderRow";
import { ConditionTableRow } from "./ConditionTable3Row";

interface ConditionTable3Props {
  loading: boolean;
  readOnly: boolean;
  canEdit: boolean;
  conditions: ConditionLineItem[];
  handleSelectCondition: (condition: ConditionLineItem) => void;
  handleChange: (condition: ConditionLineItem) => void;
  handleClick: (condition: ConditionLineItem) => void;
  setLocked: (value: boolean) => void;
  handleRejectAndResubmit: (condition: ConditionLineItem) => Promise<void>;
  handleMarkAsNotApplicable: (condition: ConditionLineItem[]) => Promise<void>;
  handleDeleteCondition: (condition: ConditionLineItem) => Promise<void>;
  handleEditCondition: (condition: ConditionLineItem) => Promise<void>;
  handleOpenConditionDocumentModal: (
    condition: ConditionLineItem
  ) => Promise<void>;
  locked: boolean;
  selectedConditions: ConditionLineItem[];
  handleSelectAll: () => void;
  allSelected: boolean;
  permit?: PermitLineItem;
}

export const ConditionTable3 = ({
  allSelected,
  canEdit,
  conditions,
  handleChange,
  handleClick,
  handleDeleteCondition,
  handleEditCondition,
  handleMarkAsNotApplicable,
  handleOpenConditionDocumentModal,
  handleRejectAndResubmit,
  handleSelectAll,
  handleSelectCondition,
  loading,
  locked,
  permit,
  readOnly,
  selectedConditions,
}: ConditionTable3Props) => {
  const memoisedHeader = useMemo(() => {
    return (
      <ConditionTableHeaderRow
        locked={locked}
        readOnly={readOnly}
        allSelected={allSelected}
        handleSelectAll={handleSelectAll}
      />
    );
  }, [locked, allSelected]);

  const memoisedRows = useMemo(() => {
    return conditions.map((data, index: number) => (
      <ConditionTableRow
        key={index}
        data={data}
        handleSelectCondition={handleSelectCondition}
        locked={locked}
        permit={permit}
        onClick={handleClick}
        onChange={handleChange}
        canEdit={canEdit}
        readOnly={readOnly}
        selectedConditions={selectedConditions}
        handleMarkAsNotApplicable={handleMarkAsNotApplicable}
        handleRejectAndResubmit={handleRejectAndResubmit}
        handleDeleteCondition={handleDeleteCondition}
        handleEditCondition={handleEditCondition}
        handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
      />
    ));
  }, [conditions, permit, locked]);

  return (
    <Box mt={2}>
      {loading ? (
        <ConditionTableSkeleton />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            {memoisedHeader}
            <TableBody>
              {memoisedRows}
              {!conditions.length ? (
                <NoRows title={"Conditions"} colSpan={6} />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

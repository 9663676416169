import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import CustomMenu from "@stories/atoms/CustomMenu/CustomMenu";
import CustomMenuItem from "@stories/atoms/CustomMenuItem/CustomMenuItem";
import {
  CellAction,
  RichTextConditionCell,
  TextConditionCell,
} from "@stories/organisms/Table/TableCells";
import {
  AddIcon,
  ArchiveProjectIcon,
  DropdownIcon,
  EditProjectIcon,
  RejectIcon,
  ReloadIcon,
} from "assets/constants";
import { AnimatePresence, motion } from "framer-motion";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import { PermitStatus } from "permit-one-common/src/entities/permit";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";

const AnimatedTableCell = motion(TableCell);

interface ConditionTableRowProps {
  locked: boolean;
  canEdit: boolean;
  readOnly: boolean;
  data: ConditionLineItem;
  selectedConditions: ConditionLineItem[];
  permit?: PermitLineItem;
  onChange: (condition: ConditionLineItem) => void;
  onClick: (condition: ConditionLineItem) => void;
  handleSelectCondition: (condition: ConditionLineItem) => void;
  handleMarkAsNotApplicable: (condition: ConditionLineItem[]) => Promise<void>;
  handleDeleteCondition: (condition: ConditionLineItem) => Promise<void>;
  handleEditCondition: (condition: ConditionLineItem) => Promise<void>;
  handleRejectAndResubmit: (condition: ConditionLineItem) => Promise<void>;
  handleOpenConditionDocumentModal: (
    condition: ConditionLineItem
  ) => Promise<void>;
}

export const ConditionTableRow = ({
  canEdit,
  data,
  handleDeleteCondition,
  handleEditCondition,
  handleMarkAsNotApplicable,
  handleOpenConditionDocumentModal,
  handleRejectAndResubmit,
  handleSelectCondition,
  locked,
  onChange,
  onClick,
  permit,
  readOnly,
  selectedConditions,
}: ConditionTableRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const cellActions: CellAction[] = readOnly
    ? []
    : [
        {
          action: handleOpenConditionDocumentModal,
          icon: <AddIcon />,
          title: "Add Documents",
        },
        {
          action: handleEditCondition,
          icon: <EditProjectIcon />,
          title: "Edit Condition",
          validateEnabled: () => {
            return (
              canEdit &&
              permit?.status !== PermitStatus.Complete &&
              data.status !== ConditionStatus.Approved
            );
          },
        },
        {
          action: (cd: any) => handleMarkAsNotApplicable([cd]),
          actionAlt: (cd: any) => handleMarkAsNotApplicable([cd]),
          icon: <ReloadIcon />,
          iconAlt: <ReloadIcon />,
          title: "Toggle Not Applicable",
          titleAlt: "Toggle Applicable",
          useAlt: (data: any) => {
            return data.markedAsNotApplicable;
          },
          validateEnabled: () => {
            return (
              canEdit &&
              permit?.status !== PermitStatus.Complete &&
              data.status !== ConditionStatus.Approved
            );
          },
        },
        {
          action: handleDeleteCondition,
          icon: <ArchiveProjectIcon />,
          title: "Delete Condition",
          validateEnabled: () => {
            return (
              canEdit &&
              permit?.status !== PermitStatus.Complete &&
              data.status !== ConditionStatus.Approved
            );
          },
        },
        {
          action: handleRejectAndResubmit,
          icon: <RejectIcon />,
          title: "Reject & Resubmit",
          validateEnabled: () => {
            return (
              canEdit &&
              (data.status === ConditionStatus.InReview ||
                data.status === ConditionStatus.Approved)
            );
          },
        },
      ];

  const enabledButtons = cellActions.filter((action: CellAction) => {
    const activeValue = action.validateEnabled
      ? action.validateEnabled(data)
      : true;
    if (!activeValue) {
      return false;
    }
    return true;
  });

  const checked = selectedConditions.includes(data);

  const finalised = false; // data?.status === ConditionStatus.Approved;
  return (
    <TableRow
      sx={{
        cursor: locked ? "pointer" : "default",
      }}
    >
      <AnimatePresence>
        {!locked ? (
          <AnimatedTableCell
            sx={{
              borderBottom: "2px solid rgba(241, 243, 244, 1)",
              borderTop: "2px solid rgba(241, 243, 244, 1)",
              maxWidth: "5%",
              width: "5%",
            }}
            align="center"
            component="td"
            initial={{ opacity: 0, x: -20 }} // Enter from the left
            animate={{ opacity: 1, x: 0 }} // Set to the default position
            exit={{ opacity: 0, x: -20 }} // Exit to the left
            transition={{
              damping: 25,
              stiffness: 200,
              type: "spring",
            }}
            layout // Enable layout transition
          >
            <Checkbox
              checked={checked}
              onChange={() => {
                handleSelectCondition(data);
              }}
            />
          </AnimatedTableCell>
        ) : null}
      </AnimatePresence>
      <TableCell
        align="center"
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
        }}
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      >
        {conditonNameToString(data.conditionAuthority, data.permitItemNumber)}
      </TableCell>
      <RichTextConditionCell
        finalised={finalised}
        value={data.conditionDetails || ""}
        locked={locked}
        onChange={() =>
          onChange({ ...data, conditionDetails: data.conditionDetails || "" })
        }
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      />
      <RichTextConditionCell
        finalised={finalised}
        value={data.conditionComments || ""}
        locked={locked}
        onChange={() =>
          onChange({ ...data, conditionComments: data.conditionComments || "" })
        }
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      />
      <TextConditionCell
        finalised={finalised}
        value={data.discipline || ""}
        locked={locked}
        onChange={() =>
          onChange({ ...data, discipline: data.discipline || "" })
        }
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      />
      <TableCell
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
        }}
        align="center"
        onClick={() => {
          return locked ? onClick(data) : undefined;
        }}
      >
        <Chip
          label={conditionStatusToString(
            data.status,
            Boolean(data.markedAsNotApplicable)
          )}
          sx={{
            backgroundColor: conditionStatusToColor(
              data.status,
              Boolean(data.markedAsNotApplicable)
            ),

            color: "black",
            cursor: locked ? "pointer" : "default",
            margin: "auto",
          }}
          size="small"
          variant="filled"
          className="chip"
        />
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "2px solid rgba(241, 243, 244, 1)",
          borderTop: "2px solid rgba(241, 243, 244, 1)",
        }}
      >
        {enabledButtons.length && (
          <>
            <Box
              sx={{
                alignItem: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <IconButton
                onClick={handleClick}
                disableRipple
                aria-label="more"
                size="large"
              >
                <DropdownIcon />
              </IconButton>
            </Box>
            <CustomMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
            >
              {enabledButtons.map(
                (action: CellAction, index: number, arr: CellAction[]) => {
                  const useAlt = action.useAlt ? action.useAlt(data) : false;
                  return [
                    <Box key={`${index}-menu-item`}>
                      <CustomMenuItem
                        action={action}
                        arr={arr}
                        data={data}
                        index={index}
                        setAnchorEl={setAnchorEl}
                        useAlt={useAlt}
                      />
                    </Box>,
                  ].filter(Boolean);
                }
              )}
            </CustomMenu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

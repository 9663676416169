/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAllFiles } from "@hooks/crud/useAllFiles";
import { useExcel } from "@hooks/crud/useExcel";
import { usePdf } from "@hooks/crud/usePdf";
import { useConfirmationDialog } from "@hooks/utils/useConformationDialog";
import { uploadFile } from "@hooks/utils/useUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import KeyIconOff from "@mui/icons-material/KeyOff";
import {
  Box,
  Grid,
  SpeedDial,
  SpeedDialProps,
  Stack,
  styled,
  Tooltip,
  Zoom,
} from "@mui/material";
import ConditionExport from "@stories/molecules/ConditionExport/ConditionExport";
import ConditionImport from "@stories/molecules/ConditionImport/ConditionImport";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import BulkConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/BulkConditionDocumentModal";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { AddIcon, ExportIcon, ImportIcon } from "assets/constants";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import { permitTypeToString } from "permit-one-common/src/entities/permit";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useEffect, useState } from "react";

import { ConditionTable3 } from "./ConditionTable3";
interface StyledSpeedDialProps extends SpeedDialProps {
  styledColour?: string;
  styledBkColour?: string;
}

export const StyledSpeedDial = styled(SpeedDial)<StyledSpeedDialProps>(
  (props) => ({
    "& .MuiSpeedDial-fab": {
      "&:hover": {
        backgroundColor: props.styledBkColour,
      },
      backgroundColor: props.styledColour,
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      left: props.theme.spacing(2),
      top: props.theme.spacing(2),
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: props.theme.spacing(2),
      right: props.theme.spacing(2),
    },
    position: "absolute",
  })
);

interface ConditionTable2Props {
  loading: boolean;
  conditions: ConditionLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  disabled?: boolean;
  permit?: PermitLineItem;
  handleOpenConditionDocumentModal: (
    condition: ConditionLineItem
  ) => Promise<void>;
  handleUpdateConditions: (condition: ConditionLineItem[]) => Promise<void>;
  handleEditCondition: (condition?: ConditionLineItem) => Promise<void>;
  handleDeleteCondition: (condition: ConditionLineItem[]) => Promise<void>;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  handleMarkAsNotApplicable: (condition: ConditionLineItem[]) => Promise<void>;
  handleRejectAndResubmit: (condition: ConditionLineItem) => Promise<void>;
}

export const ConditionTable2 = ({
  conditions,
  handleDeleteCondition,
  handleEditCondition,
  handleImportConditons,
  handleMarkAsNotApplicable,
  handleOpenConditionDocumentModal,
  handleRejectAndResubmit,
  handleUpdateConditions,
  loading,
  organisation,
  permit,
  profile,
  project,
}: ConditionTable2Props) => {
  const [isBulkModalOpen, setIsBulkModalOpen] = useState<boolean>(false);

  const handleOpenBulkModal = useCallback(() => {
    setIsBulkModalOpen(true);
  }, [isBulkModalOpen]);

  const handleCloseBulkModal = useCallback(() => {
    setIsBulkModalOpen(false);
  }, [isBulkModalOpen]);

  const { closeDialog, dialogState, openDialog } = useConfirmationDialog();
  const { PDFDownloadProgress, downloadStepPDF, getPdfReport } = usePdf();
  const { downloadStepExcel, excelDownloadProgress, getExcelReport } =
    useExcel();
  const { allFilesDownloadProgress, downloadStepAllFiles, getFullReportInfo } =
    useAllFiles();

  const isProjectOwner =
    organisation && project && project.owner?.id === organisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);
  const isPermitApprover =
    profile &&
    permit &&
    permit.approvers.map((pm) => pm.id).includes(profile.id);
  const canEdit = isProjectOwner || isPermitApprover || false;
  const readOnly = !isProjectOwner && !isPermitManager && !isPermitApprover;
  const [locked, setLocked] = useState(true);

  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const [selectedConditionsBCA, setSelectedConditionsBCA] = useState<
    ConditionLineItem[]
  >([]);

  const [selectedConditionsDA, setSelectedConditionsDA] = useState<
    ConditionLineItem[]
  >([]);

  const handleDeleteAll = useCallback(() => {
    openDialog(
      "Delete Selected Conditions",
      "Are you sure you want to delete the selected conditions?",
      "error",
      async () => {
        try {
          await handleDeleteCondition([
            ...selectedConditionsBCA,
            ...selectedConditionsDA,
          ]);
          setSelectedConditionsDA([]);
          setSelectedConditionsBCA([]);
        } catch (error) {
          console.error("Error deleting conditions:", error);
        }
      }
    );
  }, [conditions, selectedConditionsBCA, selectedConditionsDA]);

  const handleDeleteSingleCondition = useCallback(
    async (condition: ConditionLineItem) => {
      openDialog(
        "Delete Condition",
        "Are you sure you want to delete this condition?",
        "error",
        async () => {
          try {
            await handleDeleteCondition([condition]);
          } catch (error) {
            console.error("Error deleting condition:", error);
          }
        }
      );
    },
    [conditions, selectedConditionsBCA, selectedConditionsDA]
  );

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const [tabIndex, setTabIndex] = useState<number>(parseInt(status || "0"));
  const [searchTerm, setSearchTerm] = useState("");

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
    setSelectedConditionsBCA([]);
    setSelectedConditionsDA([]);
  };

  const handleOpenImportModel = useCallback(
    (flag: boolean) => {
      setImportModalOpen(flag);
    },
    [importModalOpen]
  );

  const handleOpenExportModal = useCallback(
    (flag: boolean) => {
      setExportModalOpen(flag);
    },
    [importModalOpen]
  );

  const handleConfirm = async () => {
    await dialogState.onConfirm();
    closeDialog();
  };

  const handleSelectAllBCA = useCallback(async () => {
    setSelectedConditionsBCA((prev) =>
      prev.length
        ? []
        : conditions.filter(
            (c) => c.conditionAuthority === ConditionAuthority.BCA
          )
    );
  }, [conditions, selectedConditionsBCA]);

  const handleSelectAllDA = useCallback(async () => {
    setSelectedConditionsDA((prev) =>
      prev.length
        ? []
        : conditions.filter(
            (c) => c.conditionAuthority === ConditionAuthority.DA
          )
    );
  }, [conditions, selectedConditionsDA]);

  const handleCheck = useCallback(
    (condition: ConditionLineItem) => {
      const checked =
        !selectedConditionsBCA.includes(condition) &&
        !selectedConditionsDA.includes(condition);
      if (condition.conditionAuthority === ConditionAuthority.BCA) {
        if (checked) {
          setSelectedConditionsBCA([...selectedConditionsBCA, condition]);
        } else {
          setSelectedConditionsBCA((prev) =>
            prev.filter((c) => c.id !== condition.id)
          );
        }
      } else {
        if (checked) {
          setSelectedConditionsDA([...selectedConditionsDA, condition]);
        } else {
          setSelectedConditionsDA((prev) =>
            prev.filter((c) => c.id !== condition.id)
          );
        }
      }
    },
    [selectedConditionsBCA, selectedConditionsDA]
  );

  const buttons = [
    {
      action: async () => {
        setExportModalOpen(true);
      },
      icon: <ExportIcon />,
      text: "Download Checklist",
      type: HeaderButtonType.SECONDARY,
      width: 180,
    },
    {
      action: async () => {
        setImportModalOpen(true);
      },
      icon: <ImportIcon />,
      text: "Import Checklist",
      type: HeaderButtonType.SECONDARY,
      width: 160,
    },
    {
      action: async () => {
        handleEditCondition();
      },
      icon: <AddIcon variant="secondary" />,
      text: "Add Condition",
      type: HeaderButtonType.PRIMARY,
      width: 160,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Assigned",
      value: 1,
    },
    {
      label: "In Review",
      value: 2,
    },
    {
      label: "Approved",
      value: 3,
    },
    {
      label: "Not applicable",
      value: 4,
    },
  ];

  const filteredConditions = (() => {
    switch (tabIndex) {
      case 1:
        return conditions.filter(
          (condition) =>
            condition.status === ConditionStatus.Assigned &&
            !condition.markedAsNotApplicable
        );
      case 2:
        return conditions.filter(
          (condition) =>
            condition.status === ConditionStatus.InReview &&
            !condition.markedAsNotApplicable
        );
      case 3:
        return conditions.filter(
          (condition) =>
            condition.status === ConditionStatus.Approved &&
            !condition.markedAsNotApplicable
        );
      case 4:
        return conditions.filter(
          (condition) => condition.markedAsNotApplicable
        );
      default:
        return conditions;
    }
  })();

  const bcaConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.BCA
  );
  const daConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.DA
  );

  useEffect(() => {
    if (status) {
      setTabIndex(parseInt(status));
    }
  }, [status]);

  return (
    <Grid item xs={12} md={12} border={"1px solid #e9eaeb"} borderRadius="12px">
      <Stack direction="column">
        <Header
          loading={loading}
          mainTitle={`${
            permit
              ? permit?.permitDescription
                ? permit.permitDescription
                : permitTypeToString(permit.permitType)
              : ""
          } Checklist`}
          isSubheading
          buttons={buttons}
          handleSearchChange={handleSearchChange}
          tabs={tabs}
          handleTabChange={handleTabChange}
          tabIndex={tabIndex}
          searchTerm={searchTerm}
        />
        <Grid
          container
          sx={{
            borderBottom: "1px solid #e9eaeb",
            borderRadius: "0 0 12px 12px",
          }}
        >
          <Header
            loading={loading}
            mainTitle={""}
            subTitle={"BCA Conditions"}
            isSubheading
            paddingTop="12px"
          />
          <Grid item xs={12} md={12} mt={2}>
            <ConditionTable3
              allSelected={
                bcaConditions.length > 0 &&
                selectedConditionsBCA.length === bcaConditions.length
              }
              loading={loading}
              locked={locked}
              canEdit={canEdit}
              readOnly={readOnly}
              setLocked={setLocked}
              conditions={bcaConditions}
              handleChange={(condition: ConditionLineItem) => {
                handleUpdateConditions([condition]);
              }}
              handleClick={(condition: ConditionLineItem) => {
                handleOpenConditionDocumentModal(condition);
              }}
              handleSelectCondition={(condition: ConditionLineItem) => {
                handleCheck(condition);
              }}
              selectedConditions={selectedConditionsBCA}
              handleSelectAll={function (): void {
                handleSelectAllBCA();
              }}
              handleRejectAndResubmit={handleRejectAndResubmit}
              handleMarkAsNotApplicable={handleMarkAsNotApplicable}
              handleDeleteCondition={handleDeleteSingleCondition}
              handleEditCondition={handleEditCondition}
              handleOpenConditionDocumentModal={
                handleOpenConditionDocumentModal
              }
            />
          </Grid>
        </Grid>
        <Header
          loading={loading}
          mainTitle={""}
          subTitle={"DA Conditions"}
          isSubheading
        />
        <Grid item xs={12} md={12} mt={2}>
          <ConditionTable3
            permit={permit}
            allSelected={
              daConditions.length > 0 &&
              selectedConditionsDA.length === daConditions.length
            }
            loading={loading}
            locked={locked}
            canEdit={canEdit}
            readOnly={readOnly}
            setLocked={setLocked}
            conditions={daConditions}
            handleChange={(condition: ConditionLineItem) => {
              handleUpdateConditions([condition]);
            }}
            handleClick={(condition: ConditionLineItem) => {
              handleOpenConditionDocumentModal(condition);
            }}
            handleSelectCondition={(condition: ConditionLineItem) => {
              handleCheck(condition);
            }}
            handleRejectAndResubmit={handleRejectAndResubmit}
            selectedConditions={selectedConditionsDA}
            handleSelectAll={function (): void {
              handleSelectAllDA();
            }}
            handleMarkAsNotApplicable={handleMarkAsNotApplicable}
            handleDeleteCondition={handleDeleteSingleCondition}
            handleEditCondition={handleEditCondition}
            handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
          />
        </Grid>
      </Stack>
      {canEdit ? (
        <Box sx={{ bottom: 16, position: "absolute", right: 16 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ bottom: 16, position: "absolute", right: 16 }}
          >
            {selectedConditionsBCA.length || selectedConditionsDA.length ? (
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Toggle as Not Applicable"
              >
                <StyledSpeedDial
                  ariaLabel="checklist-delete"
                  styledColour="grey"
                  styledBkColour="darkgrey"
                  open={!locked}
                  icon={<CancelIcon />}
                  direction={"up"}
                  sx={{ color: "red", position: "static" }} // Remove absolute positioning
                  onClick={() => {
                    handleMarkAsNotApplicable([
                      ...selectedConditionsBCA,
                      ...selectedConditionsDA,
                    ]);
                  }}
                />
              </Tooltip>
            ) : null}
            {canEdit &&
            (selectedConditionsBCA.length || selectedConditionsDA.length) ? (
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Bulk Upload Documents"
              >
                <StyledSpeedDial
                  ariaLabel="checklist-delete"
                  styledColour="blue"
                  styledBkColour="darkblue"
                  open={!locked}
                  icon={<ImportIcon variant="priamry" />}
                  direction={"up"}
                  sx={{ color: "white", position: "static" }} // Remove absolute positioning
                  onClick={() => {
                    handleOpenBulkModal();
                  }}
                />
              </Tooltip>
            ) : null}
            {selectedConditionsBCA.length || selectedConditionsDA.length ? (
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Delete"
              >
                <StyledSpeedDial
                  ariaLabel="checklist-delete"
                  styledColour="red"
                  styledBkColour="darkred"
                  open={!locked}
                  icon={<DeleteIcon />}
                  direction={"up"}
                  sx={{ color: "#FF0000", position: "static" }} // Remove absolute positioning
                  onClick={() => {
                    handleDeleteAll();
                  }}
                />
              </Tooltip>
            ) : null}
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={locked ? "Unlock" : "Lock"}
            >
              <StyledSpeedDial
                ariaLabel="checklist-lock"
                onClick={() => {
                  setLocked(!locked);
                  if (!locked) {
                    setSelectedConditionsBCA([]);
                    setSelectedConditionsDA([]);
                  }
                }}
                open={!locked}
                icon={locked ? <KeyIcon /> : <KeyIconOff />}
                direction={"up"}
                sx={{ position: "static" }} // Remove absolute positioning
              />
            </Tooltip>
          </Stack>
        </Box>
      ) : null}
      <Box sx={{ marginTop: "0" }}>
        {importModalOpen && project && permit ? (
          <ConditionImport
            project={project}
            permit={permit}
            bcaCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.BCA
              ).length
            }
            daCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.DA
              ).length
            }
            loading={false}
            open={importModalOpen}
            handleImportConditons={handleImportConditons}
            setOpen={handleOpenImportModel}
          />
        ) : null}
      </Box>
      {project && permit && exportModalOpen ? (
        <ConditionExport
          project={project}
          permit={permit}
          loading={false}
          open={exportModalOpen}
          setOpen={handleOpenExportModal}
          getPdfReport={getPdfReport}
          downloadStepPDF={downloadStepPDF}
          PDFDownloadProgress={PDFDownloadProgress}
          getExcelReport={getExcelReport}
          downloadStepExcel={downloadStepExcel}
          excelDownloadProgress={excelDownloadProgress}
          getFullReportInfo={getFullReportInfo}
          downloadStepAllFiles={downloadStepAllFiles}
          allFilesDownloadProgress={allFilesDownloadProgress}
        />
      ) : null}

      {isBulkModalOpen && project && permit && profile ? (
        <BulkConditionDocumentModal
          open={isBulkModalOpen}
          handleClose={handleCloseBulkModal}
          handleCheck={handleCheck}
          conditions={[...selectedConditionsBCA, ...selectedConditionsDA]}
          project={project}
          permit={permit}
          userProfile={profile}
          uploadDocument={uploadFile}
        />
      ) : null}

      <ConfirmationDialog
        {...dialogState}
        onCancel={closeDialog}
        onConfirm={handleConfirm}
      />
    </Grid>
  );
};

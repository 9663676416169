import { UploadStatus } from "@hooks/utils/useUpload";
import { DialogContentText, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { useFormik } from "formik";
import {
  ConditionLineItem,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionDocumentLineItem,
  ConditionDocumentStatus,
  createDefaultConditionDocument,
} from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { cleanFileString } from "permit-one-common/src/utils/string";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import * as yup from "yup";

import { DropZone } from "./DropZone";
import { ReadWriteDocumentTable } from "./ReadWriteDocumentTable";
import { UploadModal } from "./UploadModal";
import { Header } from "../Header/Header";

interface ConditionDocumentFormProps {
  project: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
  documentCount: number;
  documents: ConditionDocumentLineItem[];
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  createDocuments: (documents: ConditionDocumentLineItem[]) => void;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  handleSubmit: (permit: ConditionDocumentLineItem[]) => void;
  pollDocuments: () => void;
  handleClose: () => void;
  uploadFile: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
}

export const ConditionDocumentForm = ({
  canEdit,
  condition,
  createDocuments,
  deleteDocument,
  disabled,
  documentCount,
  documents,
  handleClose,
  handleSubmit,
  loading,
  permit,
  pollDocuments,
  project,
  shareCondition,
  updateDocuments,
  uploadFile,
  userProfile,
}: ConditionDocumentFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [currentUploadFile, setCurrentUploadFile] = useState<string>();
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const validationSchema = yup.object({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async () => {
      await handleSubmit(documents);
    },
    validationSchema: validationSchema,
  });

  const handleSelectFiles = useCallback(
    async (files: File[]) => {
      if (project && permit && condition) {
        const documents: ConditionDocumentLineItem[] = [];
        for (let i = 0; i < files.length; i++) {
          const currentFile = files[i];
          const currentFileName = cleanFileString(currentFile.name);
          const fileKey = `documents/${project?.id}/${
            condition?.id || 0
          }/${currentFileName}`;
          setCurrentUploadFile(fileKey);
          setUploadStatus(UploadStatus.Uploading);
          setUploadProgress(0);

          await uploadFile(
            fileKey,
            currentFile,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            (error: any) => {
              console.log(error);
              setUploadProgress(0);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
            }
          );

          let name = userProfile
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : "";
          name = shareCondition
            ? `${shareCondition.firstName} ${shareCondition.lastName}`
            : name;
          const newDocument = createDefaultConditionDocument(
            project.id,
            currentFile,
            name,
            fileKey,
            permit,
            [condition],
            userProfile?.id,
            shareCondition?.id
          );
          documents.push(newDocument);
          setUploadedIndices([...uploadedIndice, i]);
        }

        //Attribute the document to either the share condition or the user profile
        if (shareCondition) {
          documents.forEach((doc) => {
            doc.shareConditionId = shareCondition.id;
          });
        }
        if (userProfile) {
          documents.forEach((doc) => {
            doc.profileId = userProfile.id;
          });
        }

        setCurrentUploadFile(undefined);
        setUploadStatus(UploadStatus.Saving);
        await createDocuments(documents);
        setUploadStatus(UploadStatus.Idle);
      }
    },
    [
      condition,
      createDocuments,
      documentCount,
      project,
      permit,
      shareCondition,
      uploadFile,
      userProfile,
      uploadedIndice,
    ]
  );

  const onFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        e.currentTarget &&
        e.currentTarget?.files &&
        e.currentTarget?.files.length > 0
      ) {
        handleSelectFiles(Array.from(e.currentTarget.files));
      }
    },
    [
      condition,
      createDocuments,
      documentCount,
      project,
      permit,
      shareCondition,
      uploadFile,
      userProfile,
      uploadedIndice,
    ]
  );

  useEffect(() => {
    if (condition) {
      const isRenaming = documents.some(
        (d) => d.status === ConditionDocumentStatus.Renaming
      );
      if (isRenaming) {
        pollDocuments();
      }
    }
  }, [pollDocuments, condition, documents]);

  const formDisabled =
    formik.isSubmitting ||
    uploadStatus !== UploadStatus.Idle ||
    disabled ||
    loading;
  return (
    <>
      <DialogContentText
        mt={2}
        sx={{
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "0 0 10px",
        }}
      >
        <Header
          mainTitle={"Documents"}
          subTitle={
            canEdit
              ? "Select one or many files to upload to the document register"
              : "View uploaded documents"
          }
          loading={loading}
          handleSearchChange={handleSearchChange}
          searchTerm={searchTerm}
        />
      </DialogContentText>
      <Grid container sx={{ padding: "0 " }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            alignContent: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 0 20px",
          }}
        >
          {canEdit ? (
            <input
              hidden
              ref={fileInput}
              accept="*/*"
              multiple
              type="file"
              onChange={onFileChange}
            />
          ) : null}
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ padding: "0 " }}>
          {documents.length ? (
            <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
              <ReadWriteDocumentTable
                canEdit={canEdit}
                project={project}
                condition={condition}
                profileId={userProfile?.id}
                documentCount={documentCount + documents.length}
                conditionDocuments={documents}
                removeFile={deleteDocument}
                updateDocuments={updateDocuments}
                loading={loading}
                disabled={disabled}
                searchTerm={searchTerm}
              />
            </Grid>
          ) : null}
          {canEdit ? (
            <DropZone loading={loading} handleSelectFiles={handleSelectFiles} />
          ) : null}
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <StyledButton
                loading={false}
                btnType={ButtonType.Secondary}
                disabled={formDisabled}
                onClick={handleClose}
              >
                Exit
              </StyledButton>
              <StyledButton
                loading={false}
                disabled={formDisabled}
                type="submit"
              >
                Continue
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {canEdit ? (
        <UploadModal
          total={uploadProgress}
          uploadStatus={uploadStatus}
          currentDocumentName={currentUploadFile}
        />
      ) : null}
    </>
  );
};

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanFileString = exports.getPermitCodeString = exports.getPermitCode = exports.getFileNameFromUrl = exports.camelCaseToWords = exports.stripTags = exports.reversePostgresSafe = exports.makePostgresSafe = exports.getDocumentName = exports.removeFileExtension = exports.generateConditionName = exports.generateDocumentName = exports.cleanRichText = exports.validateEmail = void 0;
var condition_1 = require("../entities/condition");
var permit_1 = require("../entities/permit");
var conditionDocument_1 = require("../interfaces/conditionDocument");
var validateEmail = function (email) {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
exports.validateEmail = validateEmail;
var cleanRichText = function (input) {
    return input.replace(/<p><br><\/p>/g, "");
};
exports.cleanRichText = cleanRichText;
var generateDocumentName = function (projectName, documentDiscipline, documentCategory, documentCount) {
    return "".concat(projectName
        .substring(0, 3)
        .toUpperCase(), "-").concat((0, condition_1.conditionDisciplineToPrefix)(documentDiscipline).toUpperCase(), "-").concat((0, conditionDocument_1.documentCategorysToTitlePrefix)(documentCategory).toUpperCase(), "-").concat(padItemNumber(documentCount));
};
exports.generateDocumentName = generateDocumentName;
var generateConditionName = function (conditionNumber) {
    return padItemNumber(conditionNumber);
};
exports.generateConditionName = generateConditionName;
var padItemNumber = function (num) {
    // Convert the number to a string
    var numStr = num.toString();
    // Calculate the number of zeros to pad
    var zerosToAdd = 3 - numStr.length;
    // Pad the number with zeros
    var paddedNum = "0".repeat(zerosToAdd) + numStr;
    return paddedNum;
};
var removeFileExtension = function (fileName) {
    return fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
};
exports.removeFileExtension = removeFileExtension;
var getDocumentName = function (prefix, originalName) {
    return "".concat(prefix, ".").concat(originalName.split(".").pop());
};
exports.getDocumentName = getDocumentName;
var makePostgresSafe = function (x) {
    return Object.entries(x).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        if (typeof value === "string") {
            acc[key] = value.replace(/'/g, "''").trim();
        }
        return __assign({}, acc);
    }, x);
};
exports.makePostgresSafe = makePostgresSafe;
var reversePostgresSafe = function (x) {
    return Object.entries(x).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        if (typeof value === "string") {
            acc[key] = value.replace(/''/g, "'").trim();
        }
        return __assign({}, acc);
    }, x);
};
exports.reversePostgresSafe = reversePostgresSafe;
var stripTags = function (html) {
    if (html === null || html === "")
        return "";
    else
        html = html.toString();
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return html.replace(/(<([^>]+)>)/gi, "");
};
exports.stripTags = stripTags;
var camelCaseToWords = function (s) {
    var result = s.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
};
exports.camelCaseToWords = camelCaseToWords;
var getFileNameFromUrl = function (url) {
    var decodedUrl = decodeURI(url);
    var cleanUrl = decodedUrl.split("?")[0];
    return cleanUrl.substring(cleanUrl.lastIndexOf("/") + 1);
};
exports.getFileNameFromUrl = getFileNameFromUrl;
var getPermitCode = function (permit) {
    return (0, exports.getPermitCodeString)(permit.permitType, permit.permitNumber);
};
exports.getPermitCode = getPermitCode;
var getPermitCodeString = function (permitType, permitNumber) {
    return "".concat((0, permit_1.permitTypeToAbbreviationString)(permitType), "-").concat(permitNumber);
};
exports.getPermitCodeString = getPermitCodeString;
var cleanFileString = function (fileKey) {
    return fileKey.replace(/[^a-zA-Z0-9. ]/g, "");
};
exports.cleanFileString = cleanFileString;

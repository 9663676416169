import { Chip, Grid, Stack, styled } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CellAction,
  CollaboratorsActionCell,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import {
  ConditionLineItem,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { getPermitCodeString } from "permit-one-common/src/utils/string";

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

interface TaskTable2Props {
  loading: boolean;
  project?: ProjectLineItem;
  tasks: ConditionLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  searchTerm: string;
  handleViewtask: (document: ConditionLineItem) => void;
}

export const TaskTable2 = ({
  handleViewtask,
  loading,
  organisation,
  profile,
  project,
  searchTerm,
  tasks,
}: TaskTable2Props) => {
  // const [selectedProject, setSelectedProject] =
  //   useState<ProjectLineItem | null>(null);
  const cellActions = [
    {
      action: handleViewtask,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View Task",
    },
  ] as CellAction[];

  const colDefs = [
    {
      cellClass: "center-ag-cell",
      cellRenderer: ({ data }: any) => (
        <Chip
          label={conditionStatusToString(
            data.status,
            Boolean(data.markedAsNotApplicable)
          )}
          sx={{
            backgroundColor: conditionStatusToColor(
              data.status,
              Boolean(data.markedAsNotApplicable)
            ),
            color: "black",
          }}
          size={"small"}
          variant="filled"
          className="chip"
        />
      ),
      cellStyle: centerCellStyles,
      field: "status",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Status",
      onCellClicked: (params) => handleViewtask(params.data),
    },
    {
      field: "permitName",
      flex: 4,
      headerName: "Permit Name/Scope",
      onCellClicked: (params) => handleViewtask(params.data),
    },
    {
      field: "discipline",
      flex: 2,
      headerName: "Condition Code",
      onCellClicked: (params) => handleViewtask(params.data),
      valueFormatter: (params) => {
        return `${project?.projectCode}-${getPermitCodeString(
          params.data.permitType,
          params.data.permitItemNumber
        )}-${params.data.permitItemNumber}`;
      },
    },
    {
      field: "discipline",
      flex: 4,
      headerName: "Discipline",
      onCellClicked: (params) => handleViewtask(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: CollaboratorsActionCell,
      cellRendererParams: {
        paramKey: "approvers",
      },
      cellStyle: centerCellStyles,
      field: "approvers",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Approvers",
      onCellClicked: (params) => handleViewtask(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: CollaboratorsActionCell,
      cellRendererParams: {
        paramKey: "managers",
      },
      cellStyle: centerCellStyles,
      field: "managers",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Managers",
      onCellClicked: (params) => handleViewtask(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  return (
    <Grid item xs={12} md={12} mt={2}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={tasks}
            searchValue={searchTerm}
            context={{
              cellActions,
              organisation,
              profile,
            }}
            searchKeys={[
              "permitName",
              "permitType",
              "conditionComments",
              "assignees",
              "managers",
            ]}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};

import { uploadFile, UploadStatus } from "@hooks/utils/useUpload";
import CloseIcon from "@mui/icons-material/Close";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Zoom,
} from "@mui/material";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  conditionStatusToColor,
  conditionStatusToString,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionDocumentLineItem,
  ConditionDocumentStatus,
  createDefaultConditionDocument,
} from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { cleanFileString } from "permit-one-common/src/utils/string";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { toast, ToastOptions } from "react-toastify";

import { DropZone } from "./DropZone";
import { ReadWriteDocumentTable } from "./ReadWriteDocumentTable";
import { UploadModal } from "./UploadModal";
import { Header } from "../Header/Header";

interface PublicBulkConditionDocumentModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  conditions: ConditionLineItem[];
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
  handleCheck: (condition: ConditionLineItem) => void;
  handleClose: () => void;
  uploadDocument: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  createDocuments: (document: ConditionDocumentLineItem[]) => void;
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  updateDocuments: (document: ConditionDocumentLineItem[]) => void;
  isDocumentLoading: boolean;
  isDocumentUpdating: boolean;
  pollDocuments: () => void;
  allDocuments: ConditionDocumentLineItem[];
}

export default function PublicBulkConditionDocumentModal({
  allDocuments,
  conditions,
  createDocuments,
  deleteDocument,
  handleCheck,
  handleClose,
  isDocumentLoading,
  isDocumentUpdating,
  open,
  permit,
  pollDocuments,
  project,
  shareCondition,
  updateDocuments,
  uploadDocument,
  userProfile,
}: PublicBulkConditionDocumentModalProps) {
  const [documents, setDocuments] = useState<ConditionDocumentLineItem[]>([]);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [currentUploadFile, setCurrentUploadFile] = useState<string>();
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isDocumentLoading;
  const disabled = isDocumentUpdating;

  useEffect(() => {
    if (conditions) {
      const isRenaming = documents.some(
        (d) => d.status === ConditionDocumentStatus.Renaming
      );
      if (isRenaming) {
        pollDocuments();
      }
    }
  }, [pollDocuments, conditions, documents]);

  const handleSelectFiles = useCallback(
    async (files: File[]) => {
      if (project && permit && conditions) {
        const documents: ConditionDocumentLineItem[] = [];
        for (let i = 0; i < files.length; i++) {
          const currentFile = files[i];
          const currentFileName = cleanFileString(currentFile.name);
          const fileKey = `documents/${project?.id}/${
            permit?.id || 0
          }/${currentFileName}`;
          setCurrentUploadFile(fileKey);
          setUploadStatus(UploadStatus.Uploading);
          setUploadProgress(0);

          await uploadDocument(
            fileKey,
            currentFile,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            (error: any) => {
              console.log(error);
              setUploadProgress(0);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
            }
          );

          let name = userProfile
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : "";
          name = shareCondition
            ? `${shareCondition.firstName} ${shareCondition.lastName}`
            : name;
          const newDocument = createDefaultConditionDocument(
            project.id,
            currentFile,
            name,
            fileKey,
            permit,
            conditions,
            userProfile?.id,
            shareCondition?.id
          );
          documents.push(newDocument);
          setUploadedIndices([...uploadedIndice, i]);
        }

        //Attribute the document to either the share condition or the user profile
        if (shareCondition) {
          documents.forEach((doc) => {
            doc.shareConditionId = shareCondition.id;
          });
        }
        if (userProfile) {
          documents.forEach((doc) => {
            doc.profileId = userProfile.id;
          });
        }

        setCurrentUploadFile(undefined);
        setUploadStatus(UploadStatus.Saving);
        setDocuments(documents);
        await createDocuments(documents);
        setUploadStatus(UploadStatus.Idle);
      }
    },
    [
      conditions,
      documents,
      allDocuments,
      createDocuments,
      project,
      permit,
      shareCondition,
      uploadFile,
      userProfile,
      uploadedIndice,
    ]
  );

  useEffect(() => {
    if (allDocuments) {
      setDocuments((prev) => {
        return prev.map((doc) => {
          const foundDoc = allDocuments.find((d) => d.id === doc.id);
          if (foundDoc) {
            return foundDoc;
          }
          return doc;
        });
      });
    }
  }, [allDocuments, documents]);

  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (
        e.currentTarget &&
        e.currentTarget?.files &&
        e.currentTarget?.files.length > 0
      ) {
        handleSelectFiles(Array.from(e.currentTarget.files));
      }
    },
    [
      conditions,
      createDocuments,
      project,
      permit,
      shareCondition,
      uploadFile,
      userProfile,
      uploadedIndice,
    ]
  );

  const formDisabled =
    uploadStatus !== UploadStatus.Idle || disabled || loading;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 35px 40px" }}>
        <DialogContentText
          mt={2}
          sx={{
            color: "black",
            fontSize: "14px",
            lineHeight: "16px",
            padding: "0 0 10px",
          }}
        >
          <Header
            mainTitle={"Bulk Documents"}
            subTitle={
              "Select one or many files to upload to the document register for the selected conditions"
            }
            loading={loading}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
          />
        </DialogContentText>
        <Grid container sx={{ padding: "0 " }}>
          <Grid item md={12}>
            <Stack direction={"row"} spacing={2}>
              {conditions.map((condition) => (
                <Tooltip
                  key={condition.id}
                  TransitionComponent={Zoom}
                  placement="top"
                  title={conditionStatusToString(
                    condition.status,
                    condition?.markedAsNotApplicable || false
                  )}
                >
                  <Chip
                    size="small"
                    label={conditonNameToString(
                      condition.conditionAuthority,
                      condition.permitItemNumber
                    )}
                    variant="filled"
                    background-color={conditionStatusToColor(
                      condition.status,
                      Boolean(condition.markedAsNotApplicable)
                    )}
                    sx={{
                      backgroundColor: conditionStatusToColor(
                        condition.status,
                        Boolean(condition.markedAsNotApplicable)
                      ),
                      margin: "auto",
                    }}
                    onDelete={() => {
                      handleCheck(condition);
                      if (conditions.length === 1) {
                        handleClose();
                      }
                    }}
                  />
                </Tooltip>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              alignContent: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 0 20px",
            }}
          >
            <input
              hidden
              ref={fileInput}
              accept="*/*"
              multiple
              type="file"
              onChange={onFileChange}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "0 " }}>
          {documents.length ? (
            <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
              <ReadWriteDocumentTable
                canEdit
                project={project}
                condition={conditions[0]}
                profileId={userProfile?.id}
                documentCount={0}
                conditionDocuments={documents}
                removeFile={deleteDocument}
                updateDocuments={updateDocuments}
                loading={loading}
                disabled={disabled}
                searchTerm={searchTerm}
              />
            </Grid>
          ) : null}
          <DropZone loading={loading} handleSelectFiles={handleSelectFiles} />
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <StyledButton
                loading={false}
                btnType={ButtonType.Secondary}
                disabled={formDisabled}
                onClick={handleClose}
              >
                Exit
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
        <UploadModal
          total={uploadProgress}
          uploadStatus={uploadStatus}
          currentDocumentName={currentUploadFile}
        />
      </DialogContent>
    </Dialog>
  );
}

import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { useCallback, useState } from "react";

export interface SubmitModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const SubmitModal = ({
  onCancel,
  onConfirm,
  open,
}: SubmitModalProps) => {
  const [submitting, setSubmitting] = useState(false);

  const handlesubmit = useCallback(() => {
    setSubmitting(true);
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { p: "0 20px 20px 0", width: "80%" } }}
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle>{"Submission"}</DialogTitle>
      <DialogContent>
        <Typography>
          This action will submit the condition for review.
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton
          id="confirm-dialog-button-cancel"
          loading={false}
          onClick={onCancel}
          btnType={ButtonType.Secondary}
          disabled={submitting}
        >
          Cancel
        </StyledButton>
        <StyledButton
          data-test-id="confirm-dialog-button-ok"
          loading={false}
          onClick={() => handlesubmit()}
          btnType={ButtonType.Success}
          disabled={submitting}
        >
          Submit for Review
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

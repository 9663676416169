import { useDocument } from "@hooks/crud/useDocument";
import { useDownloadDocuments } from "@hooks/crud/useDownloadDocuments";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { DocumentTable2 } from "@stories/organisms/Tables/DocumentTable/DocumentTable2";
import { ExportIcon } from "assets/constants";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { getPermitCode } from "permit-one-common/src/utils/string";
import { useCallback, useState } from "react";

interface PermitDocumentsProps {
  loading: boolean;
  permit?: PermitLineItem;
  project?: ProjectLineItem;
}

export const PermitDocuments = ({
  loading,
  permit,
  project,
}: PermitDocumentsProps): JSX.Element => {
  const [selectedDocuments, setSelectedDocuments] = useState<
    ConditionDocumentLineItem[]
  >([]);

  const { downloadProgress, downloadStep, getDocumentsZip } =
    useDownloadDocuments();

  const handleSetSelectedDocuments = useCallback(
    (documents: ConditionDocumentLineItem[]) => {
      setSelectedDocuments(documents);
    },
    []
  );

  const handleDownloadAllDocuments = useCallback(async () => {
    if (project && selectedDocuments.length > 0) {
      await getDocumentsZip(
        project.id,
        selectedDocuments.map((d) => d.id)
      );
    }
  }, [project, selectedDocuments]);

  const { deleteDocument, documents, isDocumentLoading } = useDocument(
    project?.id,
    permit?.id
  );

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleDeleteDocument = (document: ConditionDocumentLineItem) => {
    deleteDocument(document);
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleDownloadAllDocuments,
      icon: <ExportIcon variant="secondary" />,
      text: "Download Documents",
      type: HeaderButtonType.PRIMARY,
      width: 180,
    },
  ];

  const isLoading = loading || isDocumentLoading;

  return (
    <PageContainer>
      <Header
        subTitle={
          `View all documents for ${project?.projectCode} - ${
            permit ? getPermitCode(permit) : ""
          }` || "PermitOne Documents"
        }
        mainTitle={"Documents"}
        loading={isLoading}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        buttons={buttons}
      />
      <DocumentTable2
        handleSetSelectedDocuments={handleSetSelectedDocuments}
        selectedDocuments={selectedDocuments}
        loading={isLoading}
        searchTerm={searchTerm}
        handleOpenBulk={() => {
          console.log("Not implemented");
        }}
        handleEditDocument={() => {
          console.log("Not implemented");
        }}
        handleDeleteDocument={handleDeleteDocument}
        documents={documents}
      />
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Exporting Documents Zip"}
      />
    </PageContainer>
  );
};

import {
  Grid,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { DocumentViewerModal } from "@stories/molecules/DocumentViewerModal/DocumentViewerModal";
import { ArchiveProjectIcon, ViewProjectIcon } from "assets/constants";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionDocumentLineItem,
  ConditionDocumentStatus,
} from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useState } from "react";

import { ActionCellV2, CellAction, InputTextField } from "../Table/TableCells";

interface ReadWriteDocumentTableProps {
  loading: boolean;
  searchTerm: string;
  documentCount: number;
  project: ProjectLineItem;
  canEdit: boolean;
  profileId?: string;
  disabled: boolean;
  condition?: ConditionLineItem;
  conditionDocuments: ConditionDocumentLineItem[];
  removeFile: (documents: ConditionDocumentLineItem) => void;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
}

export const ReadWriteDocumentTable = ({
  canEdit,
  condition,
  conditionDocuments,
  disabled,
  loading,
  removeFile,
  searchTerm,
  updateDocuments,
}: ReadWriteDocumentTableProps) => {
  const [documentKey, setDocumentKey] = useState<string>();

  const handleView = useCallback(
    async (conditinDocument: ConditionDocumentLineItem) => {
      const uri = conditinDocument?.url;
      if (!uri) return;
      setDocumentKey(uri);
    },
    []
  );

  const filteredDocuments = conditionDocuments.filter((document) => {
    return (
      document.documentTitle
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      document.createdByName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const cellActions: CellAction[] = [
    {
      action: handleView,
      icon: <ViewProjectIcon />,
      title: "View Document",
    },
  ];

  if (canEdit) {
    cellActions.push({
      action: async (params: ConditionDocumentLineItem) => {
        removeFile(params);
      },
      icon: <ArchiveProjectIcon />,
      title: "Remove Document",
      validateEnabled: () => {
        return (
          condition?.status !== ConditionStatus.InReview &&
          condition?.status !== ConditionStatus.Approved
        );
      },
    });
  }

  const headerStyle = {
    backgroundColor: "#FFF",
    color: "#71767f",
    fontFamily: '"Inter", sans-serif',
    fontSize: "13px",
    fontWeight: "600",
    lineHeight: "18px",
  };
  return (
    <Grid item xs={12} md={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headerStyle}>Local Name</TableCell>
            <TableCell sx={headerStyle}>Title</TableCell>
            <TableCell sx={headerStyle}>UploadBy</TableCell>
            <TableCell sx={headerStyle}>Upload</TableCell>
            <TableCell sx={headerStyle} />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDocuments.map((conditionDocument, index) => (
            <TableRow key={index}>
              <TableCell
                onClick={() => {
                  if (conditionDocument.url) {
                    handleView(conditionDocument);
                  }
                }}
              >
                {conditionDocument.url?.split("/").pop()}
              </TableCell>
              <TableCell
                onClick={() => {
                  if (conditionDocument.url) {
                    handleView(conditionDocument);
                  }
                }}
              >
                <InputTextField
                  data={conditionDocument}
                  disabled={
                    disabled ||
                    conditionDocument.status ===
                      ConditionDocumentStatus.Renaming
                  }
                  isSubmitting={loading}
                  updateDocuments={updateDocuments}
                />
              </TableCell>
              <TableCell
                onClick={() => {
                  if (conditionDocument.url) {
                    handleView(conditionDocument);
                  }
                }}
              >
                {conditionDocument.createdByName}
              </TableCell>
              <TableCell
                onClick={() => {
                  if (conditionDocument.url) {
                    handleView(conditionDocument);
                  }
                }}
              >
                {conditionDocument.created.toLocaleDateString()}
              </TableCell>
              <TableCell>
                <ActionCellV2
                  cellActions={cellActions}
                  data={conditionDocument}
                  disabled={disabled}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {documentKey ? (
        <DocumentViewerModal
          documentKey={documentKey}
          setDocumentKey={setDocumentKey}
        />
      ) : undefined}
    </Grid>
  );
};

import { Box, Skeleton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ImportIcon } from "assets/constants";
import { useCallback } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";

interface DropZoneProps {
  loading: boolean;
  handleSelectFiles: (files: FileWithPath[]) => void;
}

export const DropZone = ({ handleSelectFiles, loading }: DropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      handleSelectFiles(acceptedFiles);
    },
    [handleSelectFiles]
  );

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
      {loading ? (
        <Skeleton variant="rounded" height={100} />
      ) : (
        <Box
          {...getRootProps()}
          className="dropzone"
          sx={{
            "&:hover": {
              borderColor: "#aaa",
            },
            border: "2px dashed #ccc",
            borderRadius: "4px",
            cursor: "pointer",
            padding: "20px",
            textAlign: "center",
            transition: "border .24s ease-in-out",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <Stack
              direction={"row"}
              spacing={2}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <ImportIcon />
              <p>Drag & drop some files here, or click to select files</p>
            </Stack>
          )}
        </Box>
      )}
    </Grid>
  );
};

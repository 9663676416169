import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ConditionDocumentSteps } from "@stories/molecules/ConditionDocumentSteps/ConditionDocumentSteps";
import {
  ConditionLineItem,
  ConditionStatus,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";
import { useCallback } from "react";

import { ConditionCommentForm } from "./ConditionCommentForm";
import { ConditionDocumentForm } from "./ConditionDocumentForm";
import { ConditionReviewForm } from "./ConditionReviewForm";

interface PublicConditionDocumentModalProps {
  open: boolean;
  loading: boolean;
  disabled: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  documents: ConditionDocumentLineItem[];
  comments: ConditionCommentLineItem[];
  condition: ConditionLineItem;
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
  createDocuments: (document: ConditionDocumentLineItem[]) => void;
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  updateDocuments: (document: ConditionDocumentLineItem[]) => void;

  createComments: (comment: ConditionCommentLineItem[]) => void;
  deleteComment: (comment: ConditionCommentLineItem) => void;
  pollDocuments: () => void;
  handleUpdateCondition: (condition: ConditionLineItem) => void;
  handleClose: () => void;
  uploadFile: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  canEdit: boolean;
}

export default function PublicConditionDocumentModal({
  canEdit,
  comments,
  condition,
  createComments,
  createDocuments,
  deleteComment,
  deleteDocument,
  disabled,
  documents,
  handleClose,
  handleUpdateCondition,
  loading,
  open,
  permit,
  pollDocuments,
  project,
  shareCondition,
  updateDocuments,
  uploadFile,
  userProfile,
}: PublicConditionDocumentModalProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmitDocuments = async () => {
    handleNext();
  };

  const onSubmitComments = async () => {
    handleNext();
  };

  const onSendForReview = useCallback(async () => {
    await handleUpdateCondition({
      ...condition,
      status: ConditionStatus.InReview,
    });
    handleClose();
  }, []);

  const onAccept = async () => {
    console.log("Not implemented");
  };

  const onReject = async (_rejectMessage: string) => {
    console.log("Not implemented");
  };

  const documentsForCondition =
    documents.filter((doc) =>
      doc.conditions.map((c) => c.id).includes(condition.id)
    ) || [];

  const commentsToDisplay = comments.filter(
    (comment) => comment.conditionId === condition.id
  );

  const selectedDocumentStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <ConditionDocumentForm
            canEdit={canEdit}
            loading={loading}
            disabled={disabled}
            project={project}
            permit={permit}
            condition={condition}
            userProfile={userProfile}
            shareCondition={shareCondition}
            documents={documentsForCondition}
            createDocuments={createDocuments}
            pollDocuments={pollDocuments}
            updateDocuments={updateDocuments}
            deleteDocument={deleteDocument}
            handleSubmit={onSubmitDocuments}
            handleClose={handleClose}
            uploadFile={uploadFile}
            documentCount={documents.length}
          />
        );
      case 1:
        return (
          <ConditionCommentForm
            canEdit={canEdit}
            loading={loading}
            disabled={disabled}
            project={project}
            permit={permit}
            condition={condition}
            userProfile={userProfile}
            shareCondition={shareCondition}
            comments={commentsToDisplay}
            createComments={createComments}
            deleteComment={deleteComment}
            handleSubmit={onSubmitComments}
            handleBack={handleBack}
            handleClose={handleClose}
          />
        );
      case 2:
        return (
          <ConditionReviewForm
            loading={loading}
            canEdit={canEdit}
            handleBack={handleBack}
            handleClose={handleClose}
            onSendForReview={onSendForReview}
            onReject={onReject}
            onAccept={onAccept}
            userProfile={userProfile}
            project={project}
            permit={permit}
            condition={condition}
            comments={commentsToDisplay}
            documents={documentsForCondition}
          />
        );
    }
  })();

  const title = "Documents";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            "&:hover": {
              background: "#005cd1",
            },
            background: "#000",
            color: "#fff",
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 35px 40px" }}>
        <ConditionDocumentSteps activeStep={activeStep} loading={loading} />
        {selectedDocumentStep}
      </DialogContent>
    </Dialog>
  );
}

import KeyIcon from "@mui/icons-material/Key";
import KeyIconOff from "@mui/icons-material/KeyOff";
import { Box, Grid, Stack, Tooltip, Zoom } from "@mui/material";
import ConditionExport from "@stories/molecules/ConditionExport/ConditionExport";
import PublicBulkConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/PublicBulkConditionDocumentModal";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { ExportIcon, ImportIcon } from "assets/constants";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import { FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";
import {
  ConditionLineItem,
  ConditionStatus,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useEffect, useState } from "react";

import { PublicConditionTable3 } from "./PublicConditionTable3";
import { StyledSpeedDial } from "../ConditionTable/ConditionTable2";

interface PublicConditionTable2Props {
  loading: boolean;
  conditions: ConditionLineItem[];
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  canEdit: boolean;
  disabled: boolean;
  shareCondition?: ShareConditionLineItem;
  handleUpdateCondition: (condition: ConditionLineItem) => void;
  handleOpenConditionDocumentModal: (
    condition: ConditionLineItem
  ) => Promise<void>;
  getExcelReport: (projectId: string, permitId: string) => Promise<void>;
  getPdfReport: (projectId: string, permitId: string) => Promise<void>;
  getFullReportInfo: (projectId: string, permitId: string) => Promise<void>;
  downloadStepExcel: FileGenerationStatus;
  downloadStepPDF: FileGenerationStatus;
  downloadStepAllFiles: FileGenerationStatus;
  excelDownloadProgress: number;
  PDFDownloadProgress: number;
  allFilesDownloadProgress: number;
  uploadDocument: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  createDocuments: (document: ConditionDocumentLineItem[]) => void;
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  updateDocuments: (document: ConditionDocumentLineItem[]) => void;
  isDocumentUpdating: boolean;
  pollDocuments: () => void;
  documents: ConditionDocumentLineItem[];
}

export const PublicConditionTable2 = ({
  PDFDownloadProgress,
  allFilesDownloadProgress,
  canEdit,
  conditions,
  createDocuments,
  deleteDocument,
  documents,
  downloadStepAllFiles,
  downloadStepExcel,
  downloadStepPDF,
  excelDownloadProgress,
  getExcelReport,
  getFullReportInfo,
  getPdfReport,
  handleOpenConditionDocumentModal,
  handleUpdateCondition,
  isDocumentUpdating,
  loading,
  permit,
  pollDocuments,
  project,
  shareCondition,
  updateDocuments,
  uploadDocument,
}: PublicConditionTable2Props) => {
  const [isBulkModalOpen, setIsBulkModalOpen] = useState<boolean>(false);

  const handleOpenBulkModal = useCallback(() => {
    setIsBulkModalOpen(true);
  }, [isBulkModalOpen]);

  const handleCloseBulkModal = useCallback(() => {
    setIsBulkModalOpen(false);
  }, [isBulkModalOpen]);

  const publicConditions = conditions.filter((condition) => {
    return (
      !condition.markedAsNotApplicable &&
      condition.status !== ConditionStatus.NotAssigned
    );
  });
  const [locked, setLocked] = useState(true);
  const [selectedConditionsBCA, setSelectedConditionsBCA] = useState<
    ConditionLineItem[]
  >([]);

  const [selectedConditionsDA, setSelectedConditionsDA] = useState<
    ConditionLineItem[]
  >([]);

  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const [tabIndex, setTabIndex] = useState<number>(parseInt(status || "0"));
  const [searchTerm, setSearchTerm] = useState("");

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleOpenExportModal = useCallback(
    (flag: boolean) => {
      setExportModalOpen(flag);
    },
    [exportModalOpen]
  );

  const buttons = [
    {
      action: async () => {
        setExportModalOpen(true);
      },
      icon: <ExportIcon />,
      text: "Download Checklist",
      type: HeaderButtonType.SECONDARY,
      width: 180,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Assigned",
      value: 1,
    },
    {
      label: "In Review",
      value: 2,
    },
    {
      label: "Approved",
      value: 3,
    },
  ];

  const filteredConditions = (() => {
    switch (tabIndex) {
      case 1:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.Assigned
        );
      case 2:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.InReview
        );
      case 3:
        return publicConditions.filter(
          (condition) => condition.status === ConditionStatus.Approved
        );
      case 4:
        return publicConditions.filter(
          (condition) => condition.markedAsNotApplicable
        );
      default:
        return publicConditions;
    }
  })();

  const bcaConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.BCA
  );
  const daConditions = filteredConditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.DA
  );

  const handleSelectAllBCA = useCallback(async () => {
    setSelectedConditionsBCA((prev) =>
      prev.length
        ? []
        : conditions.filter(
            (c) => c.conditionAuthority === ConditionAuthority.BCA
          )
    );
  }, [conditions, selectedConditionsBCA]);

  const handleSelectAllDA = useCallback(async () => {
    setSelectedConditionsDA((prev) =>
      prev.length
        ? []
        : conditions.filter(
            (c) => c.conditionAuthority === ConditionAuthority.DA
          )
    );
  }, [conditions, selectedConditionsDA]);

  const handleCheck = useCallback(
    (condition: ConditionLineItem) => {
      const checked =
        !selectedConditionsBCA.includes(condition) &&
        !selectedConditionsDA.includes(condition);
      if (condition.conditionAuthority === ConditionAuthority.BCA) {
        if (checked) {
          setSelectedConditionsBCA([...selectedConditionsBCA, condition]);
        } else {
          setSelectedConditionsBCA((prev) =>
            prev.filter((c) => c.id !== condition.id)
          );
        }
      } else {
        if (checked) {
          setSelectedConditionsDA([...selectedConditionsDA, condition]);
        } else {
          setSelectedConditionsDA((prev) =>
            prev.filter((c) => c.id !== condition.id)
          );
        }
      }
    },
    [selectedConditionsBCA, selectedConditionsDA]
  );

  useEffect(() => {
    if (status) {
      setTabIndex(parseInt(status));
    }
  }, [status]);

  return (
    <Grid item xs={12} md={12} border={"1px solid #e9eaeb"} borderRadius="12px">
      <Stack direction="column">
        <Header
          loading={loading}
          mainTitle={"Conditions"}
          isSubheading
          buttons={buttons}
          handleSearchChange={handleSearchChange}
          tabs={tabs}
          handleTabChange={handleTabChange}
          tabIndex={tabIndex}
          searchTerm={searchTerm}
        />
        <Grid
          sx={{
            borderBottom: "1px solid #e9eaeb",
            borderRadius: "0 0 12px 12px",
          }}
        >
          <Header
            loading={loading}
            mainTitle={""}
            subTitle={"BCA Conditions"}
            isSubheading
            paddingTop="12px"
          />
          <PublicConditionTable3
            loading={loading}
            locked={locked}
            canEdit={canEdit}
            readOnly={false}
            conditions={bcaConditions}
            handleChange={async (condition: ConditionLineItem) => {
              handleUpdateCondition(condition);
            }}
            handleClick={(condition: ConditionLineItem) => {
              handleOpenConditionDocumentModal(condition);
            }}
            handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
            setLocked={setLocked}
            selectedConditions={selectedConditionsBCA}
            handleSelectAll={function (): void {
              handleSelectAllBCA();
            }}
            allSelected={
              bcaConditions.length > 0 &&
              selectedConditionsBCA.length === bcaConditions.length
            }
            handleSelectCondition={(condition: ConditionLineItem) => {
              handleCheck(condition);
            }}
          />
        </Grid>
        <Header
          loading={loading}
          mainTitle={""}
          subTitle={"DA Conditions"}
          isSubheading
        />
        <PublicConditionTable3
          loading={loading}
          locked={locked}
          canEdit={canEdit}
          readOnly={false}
          conditions={daConditions}
          handleChange={(condition: ConditionLineItem) => {
            handleUpdateCondition(condition);
          }}
          handleClick={(condition: ConditionLineItem) => {
            handleOpenConditionDocumentModal(condition);
          }}
          handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
          setLocked={setLocked}
          selectedConditions={selectedConditionsDA}
          handleSelectAll={function (): void {
            handleSelectAllDA();
          }}
          allSelected={
            daConditions.length > 0 &&
            selectedConditionsDA.length === daConditions.length
          }
          handleSelectCondition={(condition: ConditionLineItem) => {
            handleCheck(condition);
          }}
        />
      </Stack>

      {canEdit ? (
        <Box sx={{ bottom: 16, position: "absolute", right: 16 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ bottom: 16, position: "absolute", right: 16 }}
          >
            {selectedConditionsBCA.length || selectedConditionsDA.length ? (
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Bulk Upload Documents"
              >
                <StyledSpeedDial
                  ariaLabel="checklist-delete"
                  styledColour="blue"
                  styledBkColour="darkblue"
                  open={!locked}
                  icon={<ImportIcon variant="priamry" />}
                  direction={"up"}
                  sx={{ color: "white", position: "static" }} // Remove absolute positioning
                  onClick={() => {
                    handleOpenBulkModal();
                  }}
                />
              </Tooltip>
            ) : null}
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={locked ? "Unlock" : "Lock"}
            >
              <StyledSpeedDial
                ariaLabel="checklist-lock"
                onClick={() => {
                  setLocked(!locked);
                }}
                open={!locked}
                icon={locked ? <KeyIcon /> : <KeyIconOff />}
                direction={"up"}
                sx={{ position: "static" }} // Remove absolute positioning
              />
            </Tooltip>
          </Stack>
        </Box>
      ) : null}
      {project && permit && exportModalOpen ? (
        <ConditionExport
          project={project}
          permit={permit}
          loading={loading}
          open={exportModalOpen}
          setOpen={handleOpenExportModal}
          getPdfReport={getPdfReport}
          downloadStepPDF={downloadStepPDF}
          PDFDownloadProgress={PDFDownloadProgress}
          getExcelReport={getExcelReport}
          downloadStepExcel={downloadStepExcel}
          excelDownloadProgress={excelDownloadProgress}
          getFullReportInfo={getFullReportInfo}
          downloadStepAllFiles={downloadStepAllFiles}
          allFilesDownloadProgress={allFilesDownloadProgress}
        />
      ) : null}
      {isBulkModalOpen && project && permit && shareCondition ? (
        <PublicBulkConditionDocumentModal
          open={isBulkModalOpen}
          handleClose={handleCloseBulkModal}
          handleCheck={handleCheck}
          uploadDocument={uploadDocument}
          conditions={[...selectedConditionsBCA, ...selectedConditionsDA]}
          project={project}
          permit={permit}
          shareCondition={shareCondition}
          createDocuments={createDocuments}
          deleteDocument={deleteDocument}
          updateDocuments={updateDocuments}
          isDocumentLoading={loading}
          isDocumentUpdating={isDocumentUpdating}
          pollDocuments={pollDocuments}
          allDocuments={documents}
        />
      ) : null}
    </Grid>
  );
};

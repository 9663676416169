import { Box, Chip, Grid, Stack, Tooltip, Zoom } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { DocumentViewerModal } from "@stories/molecules/DocumentViewerModal/DocumentViewerModal";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CheckboxCell,
  HeaderCheckboxCell,
  CellAction,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useState } from "react";

interface DocumentTableProps {
  loading: boolean;
  documents: ConditionDocumentLineItem[];
  selectedDocuments: ConditionDocumentLineItem[];
  project?: ProjectLineItem;
  searchTerm: string;
  handleOpenBulk: () => void;
  handleEditDocument: (document: ConditionDocumentLineItem) => void;
  handleDeleteDocument: (document: ConditionDocumentLineItem) => void;
  handleSetSelectedDocuments: (documents: ConditionDocumentLineItem[]) => void;
}

export const DocumentTable2 = ({
  documents,
  handleSetSelectedDocuments,
  loading,
  searchTerm,
  selectedDocuments,
}: DocumentTableProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [documentKey, setDocumentKey] = useState<string>();

  const handleView = useCallback(
    async (conditinDocument: ConditionDocumentLineItem) => {
      const uri = conditinDocument?.url;
      if (!uri) return;
      setDocumentKey(uri);
    },
    []
  );
  const handleSelectAll = useCallback(() => {
    if (!selectedDocuments.length) {
      handleSetSelectedDocuments([...documents]);
    } else {
      handleSetSelectedDocuments([]);
    }
  }, [documents, selectedDocuments]);

  const handleCheck = useCallback(
    (conditionDocument: ConditionDocumentLineItem) => {
      if (selectedDocuments.map((d) => d.id).includes(conditionDocument.id)) {
        handleSetSelectedDocuments(
          selectedDocuments.filter((doc) => doc.id !== conditionDocument.id)
        );
      } else {
        handleSetSelectedDocuments([...selectedDocuments, conditionDocument]);
      }
    },
    [selectedDocuments, documents]
  );

  const [colDefs] = useState<ColDef[]>([
    {
      cellClass: "center-ag-cell",
      cellRenderer: CheckboxCell,
      cellStyle: centerCellStyles,
      field: "checkbox",
      headerClass: "centered-table-header",
      headerComponent: HeaderCheckboxCell, // Use custom header
      headerName: "",
    },
    {
      cellClass: "center-ag-cell",
      field: "documentTitle",
      flex: 4,
      headerName: "Title",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
      valueFormatter: (params) => params.data.documentTitle,
    },
    {
      field: "createdByName",
      headerName: "Creator",
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: centerCellStyles,
      field: "modified",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Uploaded",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
      valueFormatter: (params) => params.data.modified.toLocaleDateString(),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: (params: any) => {
        const MAX_VISIBLE = 2;
        const conditions = params?.data?.conditions as ConditionLineItem[];
        const visibleConditions = conditions.slice(0, MAX_VISIBLE);
        const excessConditions = conditions.slice(MAX_VISIBLE);
        const excessCount = excessConditions.length;

        return (
          <Box display="flex" justifyContent="center">
            <Stack direction={"row"} spacing={-1}>
              {visibleConditions.map((condition: ConditionLineItem) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  key={`tool-tip-employee-social-factor-${excessCount}`}
                  placement="top"
                  title={`${conditonNameToString(
                    condition.conditionAuthority,
                    condition.permitItemNumber
                  )} - ${conditionStatusToString(
                    condition.status,
                    condition.markedAsNotApplicable || false
                  )}`}
                >
                  <Chip
                    key={condition.id}
                    size="small"
                    label={conditonNameToString(
                      condition.conditionAuthority,
                      condition.permitItemNumber
                    )}
                    variant="filled"
                    background-color={conditionStatusToColor(
                      condition.status,
                      Boolean(condition.markedAsNotApplicable)
                    )}
                    sx={{
                      backgroundColor: conditionStatusToColor(
                        condition.status,
                        Boolean(condition.markedAsNotApplicable)
                      ),
                      margin: "auto",
                    }}
                  />
                </Tooltip>
              ))}
              {excessCount > 0 && (
                <Tooltip
                  TransitionComponent={Zoom}
                  key={`tool-tip-employee-social-factor-${excessCount}`}
                  placement="top"
                  title={excessConditions
                    .map(
                      (c) =>
                        `${conditonNameToString(
                          c.conditionAuthority,
                          c.permitItemNumber
                        )} - ${conditionStatusToString(
                          c.status,
                          c.markedAsNotApplicable || false
                        )}`
                    )
                    .join(", ")}
                >
                  <Chip
                    size="small"
                    label={`+${excessCount}`}
                    style={{
                      backgroundColor: "#ccc",
                      color: "#000",
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          </Box>
        );
      },
      cellStyle: centerCellStyles,
      field: "activePermits",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "Status",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[]);

  const cellActions: CellAction[] = [
    {
      action: handleView,
      icon: <ViewProjectIcon />,
      title: "View Document",
    },
  ];

  const handleOpenConfirmDialog = (_: ProjectLineItem) => {
    //setSelectedProject(project);
    setOpenConfirmDialog(true);
  };

  return (
    <Grid item xs={12} md={12} mt={2}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={documents}
        searchValue={searchTerm}
        context={{
          allAreSelected:
            documents.length > 0 &&
            selectedDocuments.length === documents.length,
          cellActions,
          handleCheck,
          handleOpenConfirmDialog,
          handleSelectAll,
          selectedIds: selectedDocuments.map((doc) => doc.id),
        }}
      />
      <ConfirmationDialog
        message={"Are you sure you want to delete this docuemnt?"}
        open={openConfirmDialog}
        title={"Delete Document"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => undefined}
      />
      {documentKey ? (
        <DocumentViewerModal
          documentKey={documentKey}
          setDocumentKey={setDocumentKey}
        />
      ) : undefined}
    </Grid>
  );
};
